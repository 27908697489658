.current-time-indicator {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  z-index: 1000;

  .red-circle {
    background: var(--color-critical);
    height: 9px;
    width: 9px;
    border-radius: 50%;
  }

  .red-line {
    border: 1px solid var(--color-critical)
  }
}
