.side-bar {
  --scrollbar-size: 8px;

  .bottomOverflowGradient {
    position: absolute;
    pointer-events: none;
    width: 100%;
    bottom: 33px;
    right: 0px;
    height: 250px;
    z-index: 101;
    background: linear-gradient(
      180deg,
      var(--color-transparent),
      var(--color-paper) 40%
    );
  }

  .topOverflowGradient {
    position: absolute;
    pointer-events: none;
    width: 100%;
    top: 0px;
    right: 0px;
    height: 80px;
    z-index: 101;
    background: linear-gradient(
      180deg,
      var(--color-paper) -10%,
      var(--color-transparent)
    );
  }

  .inner-scrollable-container {
    position: relative;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    z-index: 100;
    border-radius: 0px;
    width: 100%;
  }

  .actions-container {
    .btn {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
