.fr-active path {
  fill: var(--color-primary) !important;
}

.fr-wrapper a[target="_blank"] {
  display: none !important;
}

.fr-second-toolbar a {
  display: none;
  pointer-events: none;
}

.darkMode {
  .fr-toolbar {
    background: #414b5c;
    border: 1px solid #414b5c;

    .fr-active path {
      fill: var(--color-primary) !important;
    }
  }

  .fr-toolbar path {
    fill: #fff !important;
  }

  .fr-box.fr-basic .fr-wrapper {
    background: #011311;
    border: 1px solid #414b5c;
    border-bottom-color: #414b5c;
    color: #fff;
    top: 0;
    left: 0;
  }

  .fr-box.fr-basic .fr-element {
    color: #fff;
  }

  .fr-second-toolbar {
    border: 1px solid #414b5c;
    border-top: 0;
    background: #011311;
  }

  .fr-toolbar .fr-newline {
    background: #414b5c;
  }
}

.richText {
  min-height: 200px;
  border: 1px solid var(--color-border);
  border-radius: 8px;

  .toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    & > .box {
      display: flex !important;
    }
    button {
      padding: 4px !important;

      .button {
        height: 20px;
      }

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
