.pincode-modal-container {
  padding: 8px 34px;

  .pincode-modal-input-container {
    position: relative;
    min-width: 250px;
  }

  .pincode-error-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pincode-modal-digits-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-content: center;
    align-items: center;
    text-align: center;
  }

  .digit-container {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }

  .zero-digit {
    grid-area: 4 / 2 / 5 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    &:hover {
      cursor: pointer;
    }
  }
}
