.missing-mollie {
  border-radius: 8px;
  border: 1px solid var(--color-critical);
  background-color: var(--color-critical-bg);

  padding: 16px;
  gap: 16px;
  margin: 8px;

  svg {
    width: 32px;
    height: 32px;
  }

  display: flex;
  justify-items: flex-start;
  align-items: flex-start;
  position: relative;

  * {
    color: var(--color-critical);
  }

  .mg-bt-sm {
    margin-bottom: 4px !important;
  }

  .mg-bt-md {
    margin-bottom: 16px;
  }

  &.smaller {
    padding: 8px;
    margin: 0px;
    font-size: 90%;

    margin-bottom: 16px;

    svg {
      width: 32px;
      height: 32px;
    }

    .mg-bt-md {
      margin-bottom: 8px !important;
    }
  }
}
