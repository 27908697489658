.settings-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.change-password-form-container {
  & > :not(:last-child) {
    margin-bottom: 8px !important;
  }
}

.password-rules-container {
  & > :not(:last-child) {
    margin-bottom: 5px !important;
  }
}

.password-rule-row {
  display: flex;
  align-items: center;
}

.mobile-actions-btns-bottom-transparent {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 30px 16px 16px 16px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  z-index: 101;
  height: 130px;
  span {
    color: var(--color-text) !important;
  }
}
