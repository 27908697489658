.add-signature-input {
  align-items: center;
  justify-content: center;
}

.signature-item-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  color: var(--color-text-subdued);
}
