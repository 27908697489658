.text-field {
  &.not-multiline {
    .MuiFilledInput-input {
      padding: 20px 12px 8px;
    }
  }

  &.phone {
    .MuiFilledInput-input {
      padding: 20px 12px 8px 6px;
    }

    .MuiInputAdornment-positionStart {
      margin-right: 0px;
    }
  }

  &.small {
    .MuiFilledInput-input {
      padding: 10px 12px;
    }
  }

  .MuiFilledInput-root {
    background-color: var(--color-bg);

    &.Mui-focused {
      background-color: var(--color-bg);
    }
  }

  &.text-field-paper {
    .MuiFilledInput-root {
      background-color: var(--color-paper);

      &.Mui-focused {
        background-color: var(--color-paper);
      }
    }
  }

  .MuiInputBase-root {
    font-size: 1rem;
  }

  .MuiFormLabel-root {
    font-size: 1rem;
  }
}

.MuiMenuItem-root {
  font-size: 1rem !important;
}

.text-field-container {
  position: relative;

  .toggle-password-icon {
    position: absolute;
    right: 14px;
    top: 14px;
    &:hover {
      cursor: pointer;
    }
  }
}
