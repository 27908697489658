.modal {
  width: auto;
  margin: 32px;

  @media (max-width: 450px) {
    z-index: 20000;
  }

  &.modal-xs {
    max-width: 444px;
  }

  &.modal-sm {
    max-width: 600px;
  }

  &.modal-md {
    max-width: 960px;
  }

  &.modal-lg {
    max-width: 1280px;
  }

  &.modal-xl {
    max-width: 1920px;
  }

  &.fullWidth {
    width: calc(100% - 64px);
  }

  &.autoWidth {
    width: auto;
  }

  &.severity-warning {
    border: 2px solid var(--color-warning);
    background: var(--color-warning-bg);

    .divider {
      background: var(--color-warning-line);
    }

    * {
      border-color: var(--color-warning-line);
    }

    .btn-primary {
      background: var(--color-warning);
      border-color: var(--color-warning);

      * {
        color: var(--color-white);
      }

      &:hover {
        background: var(--color-warning-line);
      }
    }
  }

  &.severity-danger {
    border: 2px solid var(--color-critical);
    background: var(--color-critical-bg);

    .divider {
      background: var(--color-critical-line);
    }

    * {
      border-color: var(--color-critical-line);
    }

    .btn-primary {
      background: var(--color-critical);
      border-color: var(--color-critical);

      * {
        color: var(--color-white);
      }

      &:hover {
        background: var(--color-critical-line);
      }
    }
  }

  & > div:nth-child(3) {
    max-height: 80vh;
    overflow: scroll;
    --scrollbar-size: 0.1px;
    --scrollbar-height: 1;
  }
}
