#scrollingSchedule {
  position: relative;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  --scrollbar-size: 8px;

  .smallDot {
    width: 9px;
    height: 9px;
    border-radius: 9px;
    background-color: var(--color-tertiary);
  }

  .quarter-time-line {
    position: relative;
    top: 9px;
  }

  .small-resa-container {
    position: absolute;
    right: 0;
    width: calc(100% - 45px);
    cursor: pointer;
  }

  .small-tableInfo-container {
    position: absolute;
    right: 0;
    width: calc(100% - 45px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  .overlap {
    position: absolute;
    right: 0;
    width: calc(100% - 45px);
    background-position: center;
    background-size: cover;
    opacity: 0.5;
    pointer-events: none;
  }
}

.bottomOverflowGradient {
  position: absolute;
  width: calc(100% - 8px);
  bottom: 1px;
  right: 8px;
  height: 250px;
  pointer-events: none;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.4)
  );

  .darkMode & {
    background: linear-gradient(
      0deg,
      rgba(26, 32, 44, 1),
      rgba(26, 32, 44, 0.4)
    );
  }
}

.topOverflowGradient {
  position: absolute;
  width: 100%;
  top: 0px;
  right: 0px;
  height: 100px;
  z-index: 10;
  pointer-events: none;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.4)
  );

  .darkMode & {
    background: linear-gradient(
      180deg,
      rgba(26, 32, 44, 1),
      rgba(26, 32, 44, 0.4)
    );
  }
}

.blocked {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0px;
  right: 0px;
  height: 100%;
  max-height: calc(100vh - 220px);
  padding: 16px;
  z-index: 11;
  background: rgba(255, 255, 255, 0.4);

  .darkMode & {
    background: rgba(26, 32, 44, 0.4);
  }
}
