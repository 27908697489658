.LeftArrow {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  z-index: 1000;
  background: linear-gradient(
    -90deg,
    rgba(1, 19, 17, 0) 0%,
    rgba(1, 19, 17, 0.5) 105%
  );

  .MuiIconButton-root {
    border-radius: 0%;
  }
}
.RightArrow {
  position: absolute;
  right: 0;
  display: flex;
  top: 0;
  background: linear-gradient(
    90deg,
    rgba(1, 19, 17, 0) 0%,
    rgba(1, 19, 17, 0.5) 105%
  );

  .MuiIconButton-root {
    border-radius: 0%;
  }
}

// .scrollWrapper::-webkit-scrollbar{
//     width: 0;
//     height: 0;
// }
.Card_Body_Wrapper {
  align-items: flex-end;
  margin-right: 16px;
  min-height: 200px;
  position: relative;
}
.restaurantClosed {
  top: 32px;
  position: absolute;
  width: 100%;
  align-self: center;
}
.scrollWrapper {
  flex-wrap: wrap;
  overflow-x: scroll;
  scroll-behavior: smooth;
  width: 100%;
}
