#feedbackTable {
  width: 100%;
  td {
    padding: 1rem;
    border-bottom: 1px solid var(--color-border);
  }

  .bigColumn {
    max-width: 180px;
  }

  .truncateWord {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table-header {
    position: sticky;
    top: 52px;
    z-index: 10;
    background-color: var(--color-paper);
  }
}
