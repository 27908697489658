.small-card {
  &.resa-done {
    background-color: var(--color-grey-1);

    .darkMode & {
      background-color: var(--color-grey-9);
    }
  }

  &.resa-normal {
    background-color: var(--color-grey-2);

    .darkMode & {
      background-color: var(--color-grey-8);
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      filter: brightness(1) !important;
    }
  }
}
