.shift-container {
  overflow: hidden;
  border-radius: 4px;
  background: var(--color-primary);
  color: var(--color-primary-5);
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  --scrollbar-size: 0.1px;

  &.multiple {
    overflow: unset;
  }
}

.title-container {
  align-items: flex-start !important;
}

.title-container > svg {
  display: inline;
  font-size: 1rem;
  margin-right: 4px;
}

.upper-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
}

.icon-container {
  display: flex;
}

.icon-container > svg {
  font-size: 1rem;
  margin-right: 4px;
  font-size: 14px;
}

.lower-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end !important;
}
