.new-reservation-sidebar {
  .first-step-field {
    margin-top: 12px;
  }
  .second-step-field {
    margin-top: 16px;
  }
}

.tableStr-options-container {
  display: flex;
  flex-direction: column;
}
