.shift-color-container {
  position: relative;
  .current-color {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid var(--color-text);
    margin-bottom: 8px;
    margin-left: 4px;
  }

  .option-colors-container {
    z-index: 1000;
    position: absolute;
    top: 32px;
    background: var(--color-paper);
    width: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 0px;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
    .color {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-bottom: 4px;

      &.active {
        border: 2px solid var(--color-text);
      }
    }
  }
}
