.mobile-shift-card-container {
  width: 100%;
  border: 1px solid var(--color-border);
  background: var(--color-paper);
  border-radius: 6px;

  &:last-child {
    margin-bottom: 50px;
  }

  .mobile-shift-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid var(--color-border);
  }
  .mobile-shift-card-main {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--color-border);
  }

  .mobile-shift-card-main-left {
    display: flex;
    flex-direction: column;
  }

  .mobile-shift-card-main-right {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .mobile-shift-card-main-input {
    width: 50px;
    text-align: right;
    border-radius: 4px;
    input {
      border: none;
      outline: none;
      width: 50px;
      background: var(--color-border);
      text-align: right;
      color: var(--color-text-subdued);
      border-radius: 4px;
      padding: 4px;
      margin-top: 4px;
    }
  }

  .mobile-shift-card-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin: 10px 0px !important;
    }

    &.is-open {
      background-color: #e2f6f5;

      .darkMode & {
        background-color: #3d666a;
      }
    }

    &.is-closed {
      background-color: #f8e9ee;

      .darkMode & {
        background-color: #6a4b5a;
      }
    }
  }
}
