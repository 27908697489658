.guest {
  cursor: pointer;

  &:hover {
    background: var(--color-grey-1);

    .darkMode & {
      background: var(--color-grey-8);
    }
  }
}
