.slateCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  & + & {
    margin-top: 0;
  }

  .first-span {
    display: inline-block;
    margin-right: 0.75em;

    .MuiCheckbox-root {
      padding: 2px;
    }
  }

  .second-span {
    flex: 1;

    &:focus {
      outline: none;
    }
  }
}

.toolbar {
  position: sticky;
  top: 0;
  background-color: var(--color-bg);
  z-index: 10;
  .button {
    height: 24px;
  }

  .btn.btn-default {
    background-color: transparent;
  }
}

.slate-texteditor {
  p {
    margin: 0;
  }

  pre {
    padding: 10px;
    background-color: #eee;
    white-space: pre-wrap;
  }

  :not(pre) > code {
    font-family: monospace;
    background-color: #eee;
    padding: 3px;
  }

  img {
    max-width: 100%;
    max-height: 20em;
  }

  blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
  }

  blockquote[dir="rtl"] {
    border-left: none;
    padding-left: 0;
    padding-right: 10px;
    border-right: 2px solid #ddd;
  }

  table {
    border-collapse: collapse;
  }

  td {
    padding: 10px;
    border: 2px solid #ddd;
  }

  input {
    box-sizing: border-box;
    font-size: 0.85em;
    width: 100%;
    padding: 0.5em;
    border: 2px solid #ddd;
    background: #fafafa;
  }

  input:focus {
    outline: 0;
    border-color: blue;
  }

  iframe {
    width: 100%;
    border: 1px solid #eee;
  }

  h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  h2 {
    font-size: 1.625rem;
    line-height: 2rem;
  }



  [data-slate-editor] > * + * {
    margin-top: 0.75em;
  }
}

.texteditor-inner {
  padding: 8px;
}
