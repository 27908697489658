.reservation-card {
  position: relative;
  overflow: hidden;
  border-radius: 0 !important;
  padding: 4px 16px;
  background-color: var(--color-grey-0);
  cursor: grab;

  .text-subdued,
  .text-white,
  .text-3 {
    color: white;
  }

  .text-minimum-consumption {
    color: var(--color-onSecondary) !important;
    border-radius: 2px;
    padding: 0 3px;
    background: var(--color-secondary) !important;
    border-radius: 2px;
    width: fit-content !important;
  }

  &.resa-normal,
  &.resa-done,
  &.resa-failed {
    &.active {
      background-color: var(--color-grey-3);

      .darkMode & {
        background-color: var(--color-grey-7);
      }
    }

    .text-subdued {
      color: var(--color-text-subdued);
    }

    .text-white {
      color: var(--color-onSecondary);
    }

    .text-3 {
      color: var(--color-text);
    }
  }

  &.resa-upcoming {
    background-color: var(--color-primary-2);
    color: white !important;

    &.active {
      background-color: #0dafa2;
    }
  }

  &.resa-overdue {
    background-color: #f4b02a;
    color: white;

    &.active {
      background-color: #f6c25a;
    }
  }

  &.resa-seated,
  &.resa-hasOrdered {
    background-color: #b10b45;
    color: white;

    &.active {
      background-color: #e10e58;
    }
  }

  &.partly-seated {
    background-color: #d161a4;
    color: white;

    &.active {
      background-color: #df90bf;
    }
  }

  &.resa-paid {
    background-color: #5500a9;
    color: white;

    &.active {
      background-color: #5e01bb;
    }
  }

  .smallIcon {
    font-size: 0.8rem; //!important;
    transform: scale(1.1);
  }

  .warningIcon {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 100px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      // filter: brightness(0.9);
      box-shadow: inset 11em 9em 0px rgba(0, 0, 0, 0.1);
    }
  }

  .res-additional-button {
    * {
      color: inherit;
      font-size: 14px;
      font-weight: bold;
    }

    // &.uncolored {
    //   * {
    //     color: var(--color-text-subdued);
    //   }
    // }
    @media screen and (max-width: 900px) {
      * {
        font-size: 11px;
        white-space: nowrap;
      }
    }
  }
}
