.table-wrapper {
  position: absolute;
  cursor: pointer;

  // .table-name {
  //   transform: scale(1.25);
  //   transform-origin: 50% 50%;
  //   transform-box: fill-box;
  // }

  // &.xsmall {
  //   .table-time {
  //     transform: scale(2);
  //     transform-origin: 50% 50%;
  //     transform-box: fill-box;
  //   }
  // }

  // &.small {
  //   .table-time {
  //     transform: scale(1.5);
  //     transform-origin: 50% 50%;
  //     transform-box: fill-box;
  //   }
  // }

  // &.medium {
  //   .table-time {
  //     transform: scale(1.2);
  //     transform-origin: 50% 50%;
  //     transform-box: fill-box;
  //   }
  // }

  // svg {
  //   transition: transform 300ms ease;
  // }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      z-index: 10;
      circle {
        fill-opacity: 0.25;
      }

      svg {
        transform: scale(1.05);
      }

      // .tableActive {
      //   //stroke-opacity: 0.8;
      // }
    }
  }

  .circle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transform: scale(1.05);

    circle {
      fill: var(--color-text);

      // &:hover {
      //   fill-opacity: 0.25;
      // }
    }
  }
}
