.btn {
  display: inline-flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 1.2rem;
  position: relative;
  border: 1px solid transparent;

  & .btn-inner {
    display: inline-flex;
    justify-content: center;
    color: inherit;
    align-items: center;
  }

  &.btn-default {
    background: var(--color-btn-default);
    border: 1px solid transparent;
  }

  &.btn-primary {
    background: var(--color-primary);

    & span,
    & div {
      color: var(--color-onPrimary);
    }

    &:hover {
      background-color: var(--color-primary-1);
    }

    &.color-error {
      background: var(--color-critical);

      & span,
      & div {
        color: var(--color-white);
      }

      &:hover {
        background-color: var(--color-critical-line);
      }
    }
  }

  &.btn-secondary {
    background: var(--color-secondary);

    & span,
    & div {
      color: var(--color-onSecondary);
    }

    &:hover {
      background-color: var(--color-secondary-1, #545ad0);
    }
  }

  &.btn-transparent {
    background-color: transparent;
    box-shadow: none;

    &.btn-disabled {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }
  }

  &.btn-outlined {
    background-color: transparent;
    border: 1px solid var(--color-border);
    background-color: var(--color-paper);
    box-shadow: none;

    &.btn-error {
      border-color: var(--color-critical);
      color: var(--color-critical);

      span {
        color: var(--color-critical);
      }
    }

    &.btn-primary {
      border-color: var(--color-primary);
      color: var(--color-primary);
      span {
        color: var(--color-primary);
      }
    }

    &.btn-disabled {
      cursor: not-allowed;
      pointer-events: all;
      background: var(--color-paper);
      border-color: var(--color-disabled);
      color: var(--color-disabled);

      span {
        color: var(--color-disabled);
      }
    }
  }

  &.btn-with-icon {
    padding: 0.5rem;
    & > .btn-inner {
      justify-content: space-between;
    }
  }

  &.btn-loading {
    & > .btn-inner {
      opacity: 0;
    }

    & > .MuiCircularProgress-root {
      position: absolute;
    }
  }

  &.btn-full-width {
    display: flex;
    width: 100%;
  }

  &.btn-flat {
    box-shadow: none;
  }

  &.btn-disabled {
    cursor: not-allowed;
    pointer-events: all;
    background: var(--color-disabled);

    & span,
    & div {
      color: var(--color-text-subdued) !important;
    }

    &:hover {
      background-color: var(--color-disabled);
    }
  }

  & .btn-start-icon {
    margin-right: 0.5rem;
  }

  & .btn-end-icon {
    margin-left: 0.5rem;
  }
}
