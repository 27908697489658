.multiple-shifts-sidebar-wrapper {
  height: 100%;
  .multiple-shifts-card-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--color-paper);
    height: 100%;
    .multiple-shifts-card-header {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--color-border);
    }

    .shifts-list-container {
      display: flex;
      flex-direction: column;

      & > :hover {
        cursor: pointer;
        background-color: var(--color-bg);
      }
    }

    .shift-list-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;
      height: 100%;
      width: 100%;
      border-radius: 4px;
      padding: 4px 8px;
      & > :first-child {
        margin-bottom: 8px;
      }

      :hover {
        cursor: pointer;
        background-color: var(--color-bg);
      }
    }

    .shift-items {
      display: flex;
      align-items: center;
      & > :not(:last-child) {
        margin-right: 12px;
      }
    }

    .shift-list-item-circle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
  }
}
