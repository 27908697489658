.day.day-btn {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1px;

  &.today {
    background: rgba(16, 204, 185, 0.3);
  }
  &.active {
    background: var(--color-primary);

    & span {
      color: var(--color-onPrimary) !important;
    }
  }

  &.wDay-6 {
    margin-right: 0px;
  }

  &.first-day {
    border-radius: 12px 0px 0px 12px;
  }

  &.last-day {
    border-radius: 0px 12px 12px 0px;
  }

  &.day-in-between {
    background-color: var(--color-border);
  }
}
