* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: background-color 100ms ease;
  transition: color 100ms ease;
  transition: border-color 100ms ease;

  &:disabled {
    cursor: not-allowed;
  }
}

body {
  -webkit-overflow-scrolling: touch;
}

p {
  margin: 0;
}

.flex {
  display: flex;
  align-items: center;
}

.inline-flex {
  display: inline-flex;
  width: auto;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.align-start {
    align-items: flex-start;
  }
}

.space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;

  &.align-start {
    align-items: flex-start;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullSize {
  width: 100%;
  min-height: 100%;
}

.clickable {
  cursor: pointer;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inline-column {
  display: inline-flex;
  flex-direction: column;
}

.flex-grow {
  flex-grow: 10;
}

.flex-start {
  align-items: flex-start;
}

.flex-end {
  align-items: flex-end;
}

.gap-xs {
  gap: 4px;
}

.gap-sm {
  gap: 8px;
}

.gap-md {
  gap: 12px;
}

.gap-lg {
  gap: 16px;
}

.scrollY {
  overflow-y: scroll;
}

.sb-show-main.sb-main-padded {
  padding: 0 !important;
}

.rounded {
  border-radius: 4px;
}

.mg-bt-xs {
  margin-bottom: 4px !important;
}

.mg-bt-sm {
  margin-bottom: 8px !important;
}

.mg-bt-md {
  margin-bottom: 16px !important;
}

.mg-bt-lg {
  margin-bottom: 32px !important;
}

.mg-r-xs {
  margin-right: 4px !important;
}

.mg-r-sm {
  margin-right: 8px !important;
}

.mg-r-md {
  margin-right: 16px !important;
}

.mg-r-lg {
  margin-right: 32px !important;
}

.pill {
  padding: 4px 8px;
  border-radius: 24px;
  margin-right: 8px;
  background-color: var(--color-border);
}

.flex-responsive {
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;

    & > * {
      width: 100%;
    }
  }
}

.colon {
  &::after {
    content: ':';
  }
}

.dateDropdownNav {
  position: absolute !important;
  left: calc(50vw - 110px);
  width: 220px !important;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

body {
  --color-scrollbar: var(--color-paper);
  --color-scrollbar-thumb: var(--color-bg);
  --scrollbar-size: 16px;
  --scrollbar-height: 1;
}

/* total width */

*::-webkit-scrollbar {
  width: var(--scrollbar-size, 16px);
  height: calc(var(--scrollbar-size, 16px) * var(--scrollbar-height, 1));
  border-radius: calc(var(--scrollbar-size, 16px) / 4);
  background-color: var(--color-scrollbar);
}

.MuiPopover-root {
  *::-webkit-scrollbar {
    width: calc(var(--scrollbar-size, 16px) / 2);
    border-radius: calc(var(--scrollbar-size, 16px) / 4);
    background-color: var(--color-scrollbar);
  }

  *::-webkit-scrollbar-thumb {
    width: calc(var(--scrollbar-size, 16px) / 2);
    border-radius: calc(var(--scrollbar-size, 16px) / 4);
    background-color: var(--color-scrollbar-thumb);
    border: calc(var(--scrollbar-size, 16px) / 8) solid var(--color-scrollbar);

    &.darkMode & {
      background-color: var(--color-grey-7);
    }
  }
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  border-radius: calc(var(--scrollbar-size, 16px) / 4);
  background-color: var(--color-scrollbar);
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  border-radius: var(--scrollbar-size, 16px);
  background-color: var(--color-scrollbar-thumb);
  border: calc(var(--scrollbar-size, 16px) / 4) solid var(--color-scrollbar);

  &.darkMode & {
    background-color: var(--color-grey-7);
  }
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}
*::-webkit-scrollbar-corner {
  background-color: var(--color-scrollbar);
}

.fixed {
  position: fixed !important;
}

.MuiListItem-root {
  padding-left: 16px;
  padding-right: 16px;
}

html {
  @media screen and (max-width: 880px) {
    font-size: 13px;
  }
}

.not-available {
  &::before {
    content: attr(data-label);
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background-color: rgba(247, 250, 252, 0.8);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: 140%;
    letter-spacing: 0px;
    font-weight: normal;
    color: rgb(26, 32, 44);
    touch-action: none;
  }

  &.na-paper::before {
    background-color: rgba(255, 255, 255, 0.8);
  }

  &.na-secondary::before {
    color: #6369d1;
  }
}

.darkMode {
  .not-available::before {
    background-color: rgba(1, 19, 17, 0.8);
    color: #fff;
  }

  .not-available.na-paper::before {
    background-color: rgba(26, 32, 44, 0.8);
  }

  .not-available.na-secondary::before {
    color: #6369d1;
  }
}

.MuiMenu-list {
  max-height: 400px;
}

.text-field .MuiInputBase-root {
  @media only screen and (max-width: 450px) {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 450px) {
  #intercom-container {
    display: none;
  }
}

.no-translation {
  color: yellow !important;

  * {
    color: yellow !important;
  }
}

.trans-common {
  color: orange !important;

  * {
    color: orange !important;
  }
}

.trans-calendar::before {
  content: 'cal ';
}

.trans-settings::before {
  content: 'set ';
}

.trans-reservations::before {
  content: 'res ';
}

.trans-auth::before {
  content: 'a ';
}

.trans-dashboard::before {
  content: 'd ';
}

.trans-errors::before {
  content: 'e ';
}

.trans-products::before {
  content: 'p ';
}

.trans-takeAway::before {
  content: 't ';
}

.trans-allergens::before {
  content: 'al ';
}

.trans-meals::before {
  content: 'm ';
}

.trans-feedback::before {
  content: 'f ';
}

.trans-healthcheck::before {
  content: 'hc ';
}

.trans-vouchers::before {
  content: 'v ';
}

.trans-website::before {
  content: 'w ';
}

.trans-onboarding::before {
  content: 'o ';
}

.trans-tickets::before {
  content: 't ';
}

.fit-content {
  min-width: fit-content;
}

.max-800 {
  max-width: 800px;
}

.MuiPaper-root {
  background-color: var(--color-bg);

  & header {
    background-color: var(--color-paper);
  }
}

.scrollbar-5 {
  --scrollbar-size: 5px;
  --scrollbar-height: 1;
}

.scrollbar-1 {
  --scrollbar-size: 1px;
  --scrollbar-height: 1;
}

.infinity-banner {
  min-width: min-content;
  white-space: nowrap;
  position: relative;
  display: block;
}

.infinity-banner > span {
  display: block;
  animation: banner 10s infinite;
  animation-timing-function: linear;
  animation-delay: 1s;
}

.carousel {
  max-width: 100%;
}

@keyframes banner {
  0% {
    transform: translateX(0%);
  }

  94% {
    transform: translateX(-100%);
  }
  94.001% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(0%);
  }
}

.scroll-1px {
  --scrollbar-size: 1px;
}

.auto-width {
  width: auto !important;
}

.line-through {
  text-decoration: line-through;
}

select.input-style {
  font: inherit;
  font-size: 1.25rem;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 4px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.calendarscreen .hideOverflow .intercom-launcher-frame {
  display: block !important;
}

@media screen and (max-width: 450px) {
  .intercom-lightweight-app-launcher {
    display: none !important;
  }

  // .intercom-lightweight-app-launcher.show {
  //   display: block !important;
  // }
}

.hideScrollbar {
  --scrollbar-size: 0px;
  --scrollbar-height: 0;
}

.scrollbar-no-vertical {
  // --scrollbar-size: 1px;
  --scrollbar-height: 0;
}
