.calendar {
  .date {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      background-color: var(--color-primary);
    }
  }

  .date-dot {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: var(--color-tertiary);
    margin-top: 1px;
  }
}
