.dropdown-options-container {
  max-height: 280px;
  width: 100%;
  overflow: scroll !important;
  padding: 0px;
  --scrollbar-height: 0;
}

.dropdown-title-container {
  width: 100%;
  padding: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  z-index: 10;
  position: sticky;
  top: 0px;
  background: var(--color-paper);
}

.dropdown-item {
  padding: 8px 12px;
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
}

.dropdown-item.active {
  background: var(--color-grey-2);

  .darkMode & {
    background: var(--color-grey-8);
  }
}

.dropdown-info {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}

.mobile-dropdown {
  background-color: var(--color-paper);
  border: 1px solid var(--color-border);
  border-radius: 6px;
}

.search-bar {
  height: 28px !important;
  background: var(--color-grey-2);

  .darkMode & {
    background: var(--color-grey-8);
  }
}
