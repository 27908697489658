.title_with_icon {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 10px;
  margin-bottom: 16px;

  .icon_container {
    color: var(--color-text-subdued);
    svg {
      color: var(--color-text-subdued);
      width: 24px;
      height: 24px;
    }
  }

  .main_container {
    .header {
      h5 {
        margin-bottom: 2px;
      }

      p {
        margin-bottom: 8px;
      }
    }
  }
}
