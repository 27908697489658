.week-days-select {
  .weekDay {
    border-radius: 14px;
    background-color: var(--color-border);
    width: 28px;
    height: 28px;
    margin-right: 8px;
    padding: 2px;
    text-align: center;
    margin-top: 8px;
    cursor: pointer;

    &:hover {
      background-color: var(--color-disabled);
    }
  }

  .selected {
    background-color: var(--color-primary);
    &:hover {
      background-color: var(--color-primary-1);
    }
  }
}
