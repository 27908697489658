.loadingScreen {
  display: flex;
  z-index: 100001;
  position: -webkit-sticky;
  position: sticky;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: var(--color-bg, '#ffffff');
  color: var(color-primary);
}
