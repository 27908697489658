.current-offer-shift-card-container {
  height: 100%;
  .inner-scrollable-container {
    padding: 0px !important;
    width: 100%;
  }
  .details-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .details-row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 10px 16px;
      border-bottom: 1px solid var(--color-border);
      width: 100%;
      :nth-child(2) {
        text-align: right;
      }
    }
  }
}
