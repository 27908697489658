.AccordionInner-enter {
  height: 0%;
  transition: height 200ms;
}

.AccordionInner-enter-active {
  height: 100%;
  transition: height 200ms;
}

.AccordionInner-exit {
  height: 100%;
  transition: height 200ms;
}

.AccordionInner-exit-active {
  height: 0%;
  transition: height 200ms;
}
