.menuItem {
  padding: 8px 16px;
  border: 1px solid transparent;
  border-bottom: 1px solid var(--color-border);

  &.iconRight {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.Mui-selected {
    background-color: #e7fdfb;

    .darkMode & {
      background-color: var(--color-primary-3);
    }

    border: 1px solid var(--color-primary);

    &:first-of-type {
      border-radius: 0.25rem 0.25rem 0px 0px;
    }

    &:not(:only-child):last-of-type {
      border-radius: 0px 0px 0.25rem 0.25rem;
    }
  }
}
