.navbar {
  z-index: 1299;
  position: sticky;
  top: 0px;

  .box {
    position: unset;
  }

  .slash {
    margin: 0 0.3rem;
  }

  .breadCrumb {
    cursor: pointer;

    &:hover {
      color: var(--color-primary);
    }
  }

  &:not(.reservationHeader) {
    padding: 8px 24px !important;
  }

  &.reservationHeader {
    padding: 8px 8px !important;

    .btn {
      padding: 0px 1rem;
      max-height: 32px;
      height: 32px;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 8px !important;
  }
}

.demo-mode-banner{
  z-index: 1299;
  position: fixed;
  bottom: 0px;
  right: -296px;
  width: 429px;
  background-color: #feb8b0;
  /* text-align: center; */
  padding: 10px;
  color: var(--color-critical);
  font-weight: bold;
  transform: scale(0.75);
  transition: right ease 300ms;

  &:hover {
    right: -50px;

  }
}
