.selected-row {
  background: var(--color-grey-2);

  .darkMode & {
    background: var(--color-grey-7);
  }
}

.simple-table-wrapper{
  width: 100%;

  @media only screen and (max-width: 800px){
    width: 700px !important;
  }
}