#guest-book-screen {
  .fixed-header {
    position: fixed;
    top: 52px;
    left: 80px;
    z-index: 10;
    width: calc(100% - 104px);
    background-color: var(--color-bg);
    padding: 24px 0px;
    overflow-x: hidden;
  }

  .featureNavigation {
    top: 0px;
    position: fixed;
    left: 20px;
    bottom: 0px;
    z-index: 1;
  }

  .fixed-nav {
    position: fixed;
    z-index: 100;
  }

  .fixed-right-pannel {
    position: sticky;
    position: -webkit-sticky;
    top: 125px;
  }
}

.new-guest-select {
  margin: 0px 0 9px !important;
}
