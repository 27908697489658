.selectInput {
  width: 100%;
  & > div {
    padding: 10px 16px;
    background: var(--color-bg);
    border-radius: 4px;
    // border: 1px solid var(--color-border);
  }
  text-align: left;
  .MuiSelect-icon {
    right: 6px;
  }
}

.selectWithLabel {
  .selectInput {
    margin-top: 5px;

    span {
      display: block;
      margin-left: -3px;
      margin-top: 6px;
    }
  }
}

.select-MenuItem {
  min-height: 20px;
  padding: 8px 16px !important;
}

.select-menu {
  .MuiPaper-root {
    margin-top: 40px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
    background: var(--color-bg);
    border-color: transparent;
    max-height: 300px;
    overflow-y: scroll;

    ul {
      display: flex;
      flex-direction: column;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}

.select-menu-zindex {
  z-index: 9999;
}
