#image-list {
  .image-input {
    position: absolute;
    z-index: 1000;
    padding: 10px;
    opacity: 0;
    width: 130px;
  }
}

.bottom-part-image-modal {
  .mr-16 {
    margin-right: 16px !important;
  }
}

#image-list,
#instagram-image-list {
  .image-container {
    .image {
      width: 100%;
      height: 150px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 4px;
      border: 1px solid var(--color-border);

      &:hover {
        border: 4px solid var(--color-border);
      }

      &.active {
        border: 4px solid var(--color-primary);
      }
    }

    .image-checkbox {
      float: right;
      width: fit-content;
      display: none;

      .MuiCheckbox-root {
        color: var(--color-primary);
      }

      &.checked {
        display: block;
      }
    }

    &:hover {
      .image-checkbox {
        display: block;
      }
    }
  }
}

#instagram-image-list {
  .no-instagram-screen {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(90vh - 125px);
    overflow-y: scroll;
    .insta-logo {
      width: 84px;
      height: 84px;
      margin-bottom: 16px;
    }
    .mb-16 {
      margin-bottom: 16px;
    }
    .mb-40 {
      margin-bottom: 40px;
      max-width: 50%;
    }
    .insta-text-field {
      margin-bottom: 24px !important;
      display: block;
      width: 256px;
      input {
        text-align: center;
      }
      .MuiFilledInput-root {
        width: 256px;
      }
    }
  }

  .instagram-screen {
    .insta-header {
      margin-top: 16px;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .insta-logo {
        width: 108px;
        height: 108px;
        margin-right: 24px;
      }
      .text-part {
        max-width: 60%;
        margin-right: 53px;
        .mb-16 {
          margin-bottom: 16px;
        }
      }
      .input-part {
        display: flex;
        flex-direction: column;
        .mb-16 {
          margin-bottom: 16px !important;
          input {
            text-align: center;
          }
        }
        .refetch-btn {
          background-color: var(--color-border);
        }
      }
    }
  }
}

@media (hover: none) {
  .image-container {
    .image-checkbox {
      display: block !important;
    }
  }
}

#image-details {
  // max-width: 272px;
  width: 38%;
  border-left: 1px solid var(--color-border);
  padding: 0;
  height: calc(90vh - 125px);
  overflow-y: scroll;

  .image-miniature {
    height: 150px;
    border-radius: 4px;
  }

  .outlined-input {
    .MuiOutlinedInput-root {
      padding: 10px 16px;
      background-color: var(--color-bg);
      input {
        padding: 0px;
      }
    }
  }

  .image-tag {
    background-color: var(--color-primary);
    border-radius: 132px;
    padding: 6px 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
  }
}
