.iconButtonSize {
  width: 20px;
  height: 20px;
}
.searchInput {
  padding: 3px;
  width: 100%;

  .MuiInputBase-input {
    font-size: 16px;
  }
}
