//FULLSCREEN MODAL STYLES
.mobile-modal-container {
  z-index: 10000;
  position: fixed;
  background: var(--color-bg);
  height: 100%;
  width: 100%;
  // height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mobile-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  background: var(--color-paper);
}

.mobile-modal-header-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
}

.mobile-modal-main {
  margin-bottom: 120vh !important;
  background: var(--color-bg);
  // height: 100vh;
  height: calc(100vh - 48px);
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  --scrollbar-size: 0px;
  --scrollbar-height: 0;
  // padding-bottom: 200px;
}

// .mobile-modal-footer {
// }

// SMALL MODAL STYLES
.mobile-modal-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.mobile-modal-container-small {
  border-radius: 8px 8px 0px 0px;
  height: 50%;
  position: fixed;
  top: 50%;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.mobile-modal-header-small {
  border-radius: 8px 8px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  background: var(--color-paper);
}

.mobile-modal-main-small {
  padding: 16px;
  background: var(--color-bg);
  min-height: calc(100% - 47px);
  display: flex;
  flex-direction: column;
}

// .mobile-modal-footer-small {
// }
