.waitinglist-side-bar {
  position: relative;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 100;
  border-radius: 0px;

  --scrollbar-size: 0px;
  --scrollbar-height: 0;

  // &::-webkit-scrollbar {
  //   display: none;
  // }
  .reservation-field {
    border-bottom: 1px solid var(--color-border);
    padding: 10px 16px;

    &.no-border {
      border-bottom: none;
    }
  }

  .subdued-input {
    color: var(--color-text-subdued);
    background-color: var(--color-paper);
    // max-width: 155px;
    padding: 0px !important;
    display: block;
    border: none;
    width: fit-content;

    .text-3 {
      padding-right: 24px !important;
    }

    &:focus {
      outline: none;
      // background-color: red;
    }

    &[type='date'] {
      &::-webkit-calendar-picker-indicator {
        background: url('../../../../Components/Atoms/Icons/CalendarLight.svg')
          center/80% no-repeat;
        color: var(--color-text-subdued);
        opacity: 1;
      }
    }

    .MuiSelect-select {
      span {
        color: var(--color-text-subdued) !important;
        font-size: 1rem;
        line-height: 140%;
        letter-spacing: 0px;
        font-weight: normal;
        position: relative;
      }
    }
  }

  .dark-mode {
    .subdued-input {
      &[type='date'] {
        &::-webkit-calendar-picker-indicator {
          background: url('../../../../Components/Atoms/Icons/CalendarDark.svg')
            center/80% no-repeat;
        }

        &::-webkit-calendar-picker-indicator {
          width: 20px;
        }
      }

      .darkMode & {
        input::-webkit-calendar-picker-indicator {
          background: url('../../../../Components/Atoms/Icons/CalendarLight.svg')
            center/80% no-repeat;
        }
      }
    }
  }

  input::-webkit-datetime-edit {
    display: inline-block;
    min-width: 0;
    overflow: hidden;
    width: 20px;
  }

  .text-right {
    text-align: right;
    margin-right: 2px;

    input {
      text-align: right;
    }
  }

  .reservation-checkbox {
    width: fit-content;
    margin-right: 11px;
    span {
      padding: 0px !important;
    }
  }
}
