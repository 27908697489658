.guestsKey {
  justify-content: center;
  max-width: 35px;
  cursor: pointer;

  &:hover {
    background: var(--color-grey-2);

    .darkMode & {
      background: var(--color-grey-8);
    }
  }

  &:active {
    background: var(--color-grey-3);

    .darkMode & {
      background: var(--color-grey-7);
    }
  }
}
