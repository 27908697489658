.filterButton {
  background-color: var(--color-btn-default);
  min-width: 50px;
  border-radius: 50px;
  margin: 0px !important;

  cursor: pointer;
  &.filterApplied {
    background-color: var(--color-primary);
  }
}

.AttributeContainer {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow-y: scroll;
  --scrollbar-size: 1px;
  height: 160px;
}

.textFieldMargin {
  margin: 8px;
}

.guest-contact-select {
  width: 100%;
}

.guest-contact-select > div {
  border: none !important;
  border-radius: 4px 4px 0px 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  background-color: var(--color-bg) !important;
  padding-bottom: 2px !important;
  padding-top: 20px !important;
}

.alignFlexStart {
  align-items: flex-start;
  justify-content: flex-start;
}

.customAttribute {
  align-items: flex-end;
  justify-content: flex-end;
  cursor: pointer;
}

.delete-guest-container {
  text-align: right;

  .delete-guest-btn {
    background-color: var(--color-critical) !important;
    padding: 9px 52px !important;
    margin-top: 24px;
    span {
      color: var(--color-onSecondary) !important;
    }
  }
}
