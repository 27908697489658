.popoverContainer {
  --scrollbar-size: 8px;
  //z-index: 30000 !important;
  .popover {
    display: flex;
    flex-direction: column;

    .darkMode & {
      background-color: var(--color-grey-8);
      --color-scrollbar: var(--color-grey-8);
      // --color-scrollbar-thumb: var(--color-grey-8);
    }
  }
}
