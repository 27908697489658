.calendar-screen {
  .featureNavigation {
    top: 0px;
    position: fixed;
    left: 8px;
    bottom: 0px;
  }

  .calendar-container {
    position: absolute;
    top: 48px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: grid;
    width: calc(100vw - 0px);
    height: calc(100vh - 50px);
    grid-template-columns: 340px 1fr;
    grid-template-rows: 100%;

    &.shift-open {
      grid-template-columns: 340px 1fr 280px;
    }

    &.editMode {
      grid-template-columns: 1fr 500px;

      @media screen and (max-width: 1300px) {
        grid-template-columns: 1fr 400px;
      }

      .calendar-left {
        display: none;
      }
    }
  }

  .calendar-left {
    height: calc(100vh - 48px);
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: var(--color-bg);
    min-width: 340px;
  }

  .multiple-shifts-sidebar {
    display: flex;
  }
}

@media (hover: none) and (pointer: coarse) and (min-height: 600px) {
  .hideOverflow {
    overflow: hidden;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
  }
}
