.new-table {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid transparent;
  border-radius: 8px;

  .active-border {
    width: 86%;
    height: 86%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 2px), calc(-50% + 2px));
    border: 2px solid transparent;
    border-radius: 8px;
  }

  .table-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: #fff;

    span {
      display: inline-block;
      padding: 2px;
      border-radius: 2px;
      color: inherit;
    }

    .timeline-bar {
      fill: #fff;
    }

    &.table-alert {
      background-image: url('./alert-table.svg');
      background-position: center;
      background-size: 150%;
      background-repeat: no-repeat;
    }

    &.table-blocked {
      background-image: url('./blocked-table.svg');
      background-position: center;
      background-size: 70%;
      background-repeat: no-repeat;
    }
  }

  &.available {
    .bg-circle {
      fill: rgba(0, 0, 0, 0.25);

      .darkMode & {
        fill: rgba(255, 255, 255, 0.2);
      }
    }
  }

  &.isOver {
    border-color: var(--color-textPrimary);
  }

  &.active {
    .active-border {
      border-color: var(--color-textPrimary);
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .active-border {
        border-color: var(--color-textPrimary);
      }
    }
  }

  .time {
    position: absolute;
    margin: 0px auto;
    padding: 2px;
    font-size: 14px;
    line-height: 14px;
    border-radius: 2px;
    z-index: 2;
  }

  img {
    position: absolute;
    bottom: 2px;
    right: 2px;
    z-index: 10;
  }

  &.border-8long {
    .active-border {
      height: 90%;
      width: calc(100% - 4px);
    }
  }

  &.border-bar1 {
    .active-border {
      height: calc(100% - 4px) !important;
      width: calc(75% - 4px) !important;
    }
  }

  &.border-8 {
    .active-border {
      height: calc(100% - 4px) !important;
      width: calc(100% - 4px) !important;
      border-radius: 50%;
    }
  }

  &.border-4round,
  &.border-3round {
    .active-border {
      height: calc(100% - 4px) !important;
      width: calc(100% - 4px) !important;
      border-radius: 50%;
    }
  }

  &.border-4 {
    .active-border {
      height: calc(105% - 4px) !important;
      width: calc(105% - 4px) !important;
    }
  }

  &.border-4l {
    .active-border {
      height: 88%;
      width: 66%;
    }
  }

  &.border-2 {
    .active-border {
      height: 70%;
      width: 90%;
    }
  }
}
