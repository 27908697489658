.container {
  padding: 0 80px;
  margin: auto;

  & > .MuiGrid-container {
    min-height: calc(100vh - 150px);
  }

  &.dashboard {
    max-width: 1024px;

    @media screen and (max-width: 960px) {
      padding: 0 40px;
    }

    @media screen and (max-width: 600px) {
      padding: 0 16px;
    }
  }

  &.feature {
    padding-right: 24px;
    margin-top: 24px;
  }

  &.reservations {
    padding-right: 24px;
    padding-left: 52px;
    margin-top: 24px;
  }
}
