.selectCalendar {
  margin-top: 38px;
  .optionSection {
    margin-top: 13px;
    .oneOption {
      margin-top: 13px;
      color: var(--color-text-subdued);
      cursor: pointer;
      .eventLogo {
        width: 24px;
        height: 24px;
        color: inherit;
        margin-right: 16px;
      }
    }
  }
  .currentOption {
    color: var(--color-primary) !important;
  }
}
