.product-icon {
  .box {
    background-color: var(--color-secondary);
    border-radius: 6px;
  }

  &.is-last-item {
    order: 2;
  }

  &.isNew {
    position: relative;
    .new-badge {
      position: absolute;
      top: -10px;
      right: -20px;
      z-index: 10;
      padding: 3px 6px;
      border-radius: 20px;
      color: var(--color-white);
      background-color: var(--color-tertiary);
      border: 2px solid var(--color-white);
      font-weight: 500;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  &.isLocked {
    position: relative;
    .center {
      // background-color: #718096;
      // opacity: 0.5;
    }

    .locked-icon {
      color: #718096;
      position: absolute;
      top: -10px;
      right: -20px;
      z-index: 10;
    }
  }

  &.hidden {
    .box {
      position: relative;
      background-color: #b0b4eb;

      .darkMode & {
        background-color: #253255;
      }

      img {
        opacity: 0.5;
      }

      .hideLabel {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bolder;
        color: var(--color-text);
      }
    }

    .mobile-version {
      background-color: var(--color-paper);
      border: solid 1px #b0b4eb;
      max-width: 96px;
      max-height: 96px;
      width: max-content;
    }
  }
}
