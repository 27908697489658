.richAd {
  padding: 20px;
  position: relative;
  background-color: var(--color-secondary);
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  justify-content: flex-start;
  margin: 20px 10px 40px 10px;

  .text {
    color: #fff;
    z-index: 2;
    position: relative;

    &.title {
      margin-bottom: 5px;
    }

    &.description {
      color: #cbd5e0;

      strong {
        color: #fff;
      }

      margin-bottom: 12px;
    }
  }

  img {
    width: 200px;
    position: absolute;
    right: 0px;
    top: 20px;
    z-index: 0;
  }

  .buttons {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 0px;
    left: 20px;
    transform: translateY(50%);
  }
}
