.attachments {
  position: relative;
  overflow-x: scroll;
  padding: 8px;
  width: 100%;
  min-height: 70px;

  --scrollbar-size: 8px;

  .rail {
    overflow-x: scroll;
    width: 100%;

    .railInner {
      width: fit-content;
      padding-right: 120px;
    }

    .attachment {
      position: relative;
      background-color: var(--color-paper);
      margin-right: 8px;
      justify-content: space-between;
      align-items: center;
      min-width: max-content;
      text-decoration: none;

      .box {
        margin-right: 8px;

        .icon {
          margin-right: 8px;
        }
      }
    }
  }

  .uploadAttachment {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 13px 8px 6px;
    width: auto;
    background: linear-gradient(
      270deg,
      var(--color-paper) 82.93%,
      var(--color-transparent) 100%
    );
  }
}

.attachments-flex {
  display: flex;
  flex-direction: column !important;
}

.columnDirection {
  .rail {
    overflow-x: visible !important;
  }
  .railInner {
    flex-direction: column;
    padding-right: 0px !important;
    & > :not(:last-child) {
      margin-bottom: 8px;
    }
    .attachment {
      min-width: auto;
    }
  }
}
