.eventModal {
  position: fixed;
  width: auto;
  top: 56px;
  right: 6px;
  height: 420px;
  display: flex;
  align-items: stretch;
  z-index: 9000;
  flex-direction: row-reverse;
  transform-origin: top right;

  .tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: sticky;
    top: 0px;
    background-color: var(--color-paper);

    & > * {
      opacity: 0.6;
    }

    .active {
      font-weight: bold;
      opacity: 1;
    }
  }

  .mainWindow {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-bottom: 0px;
    background: var(--color-paper);

    .list {
      flex-grow: 100;
      overflow: scroll;
      width: calc(100% + 20px);
      padding-left: 10px;
      transform: translateX(-10px);
      --scrollbar-height: 0;
      --scrollbar-size: 8px;
    }
  }

  .selectedEvent {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: var(--color-paper);
    margin-right: 10px;

    .callActions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 8px;

      &.oneButton {
        grid-template-columns: 1fr;
      }
    }

    .resCard {
      padding: 8px;
      border: 1px solid var(--color-border);
      border-radius: 4px;
      margin-bottom: 8px;

      * {
        display: block;
      }
    }

    .conversationWrapper {
      --scrollbar-height: 0;
      width: calc(100% + 11px);
      margin-bottom: 8px;
    }

    .callEvent {
      margin-bottom: 12px;
      margin-top: 12px;
      text-align: center;

      div {
        display: inline-flex;
        padding: 4px 6px;
        border-radius: 4px;
        background: #333;
        color: #fff;
        font-size: 80%;
        max-width: 80%;
      }
    }

    .callConversation {
      * {
        * {
          max-width: 80%;
          padding: 6px 8px;
          border-radius: 4px 12px 12px 12px;
          background: var(--color-border);
          color: var(--color-text);
          font-size: 80%;
          margin-bottom: 6px;
          min-width: 30px;
        }
      }

      .caller {
        display: flex;
        justify-content: flex-end;
        * {
          border-radius: 12px 4px 12px 12px;
          background: var(--color-primary);
          color: var(--color-onPrimary);
        }
      }
    }
  }

  .hideOverflow {
    .siaModal {
      top: 56px;
      right: 10px;
    }

    &.sidebarOpen {
      .siaModal {
        top: 56px;
        right: 290px;
      }
    }
  }

  .slider-page {
    position: absolute;
    width: 100%;
    height: 100px;
    padding: 12px;
    will-change: transform;
  }

  .slider-page > div {
    touch-action: none;
    width: 100%;
    height: 66px;
    will-change: transform;

    * {
      touch-action: none;
      user-select: none;
    }
  }

  .slider-container {
    display: flex;
    align-items: center;
    height: 104px;
    justify-content: center;
  }

  .slider-wrapper {
    width: 100%;
    height: 100%;
  }

  .currentRuleCard {
    display: flex;
    justify-content: space-between;
    background: #333;

    height: 100%;

    .MuiIconButton-root {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.sidebarOpen {
  .eventModal {
    right: 290px;
  }
}

.callListItem {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  padding-left: 20px;
  align-items: center;
  position: relative;
  cursor: pointer;

  .status {
    display: none;
  }

  &:hover {
    background: var(--color-bg);
  }

  &.active {
    background: var(--color-bg);
  }

  &.new {
    .status {
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 6px;
      background: var(--color-secondary);
    }
  }

  &.error {
    * {
      color: var(--color-critical);
    }

    .status {
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 6px;
      background: var(--color-critical);
    }
  }

  &.reservation {
    .status {
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 6px;
      background: var(--color-primary-1);
    }
  }
}
.reservation-field {
  border-bottom: 1px solid var(--color-border);
  padding: 10px 16px;

  &.no-border {
    border-bottom: none;
  }
}

@media screen and (max-width: 450px) {
  .siaModal {
    flex-direction: row;
    top: 42px;
    right: 10px;
    gap: 10px;
    height: calc(100vh - 72px);

    .mainWindow {
      width: calc(100vw - 20px);
    }

    .currentCall {
      width: calc(100vw - 20px);
      margin-right: 0px;
    }
  }
}
