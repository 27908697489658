.calendar-week {
  height: 100%;
  width: 100%;
  overflow: scroll;
  //max-height: 80vh;

  --color-scrollbar: var(--color-paper);
  // --color-scrollbar-thumb: var(--color-paper);
  --scrollbar-size: 8px;

  table {
    position: relative;
    border-spacing: 0px;
    width: 100%;
    background: var(--color-paper);

    .darkMode & {
      background: var(--color-bg);
    }

    tr {
      height: 32px;
    }

    td {
      min-width: 96px;
      border: 1px solid var(--color-border);
      border-top: none;
    }

    thead {
      position: sticky;
      top: 0px;
      z-index: 1000;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1),
        0px 0px 2px rgba(0, 0, 0, 0.2);
      background: var(--color-paper);
      border-color: transparent;
    }

    .shift-wrapper {
      position: absolute;
      padding: 1px;
      background: var(--color-paper);

      display: flex;
      border-radius: 3px;

      .darkMode & {
        background: var(--color-bg);
      }
    }

    .blank {
      width: 51px;
      position: sticky;
      left: 0px;
      z-index: 15;
      height: 100%;
      background: var(--color-paper);
    }

    .time-header {
      width: 51px;
      height: 32px;
      position: sticky;
      left: 0px;
      z-index: 200;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: var(--color-paper);
      border-right: 1px solid var(--color-border);

      .darkMode & {
        background: var(--color-bg);
      }

      span {
        position: absolute;
        top: 0px;
        right: 4px;
        transform: translate(0px, -50%);
      }
    }
  }
}
