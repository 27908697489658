.errorCard {
  border-color: var(--color-critical) !important;
  background: var(--color-tertiary-3) !important;

  & * {
    border-color: var(--color-critical) !important;
  }

  .divider {
    background-color: var(--color-critical) !important;
  }
}
