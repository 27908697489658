// .product-icons {
//   display: flex;
//   justify-content: flex-start;
//   align-content: flex-start;
//   flex-wrap: wrap;
//   margin: 0 -24px 24px -24px !important;
//   width: calc(100% + 48px) !important;

//   & > * {
//     margin: 0 24px 24px 24px !important;
//   }

//   @media screen and (max-width: 600px) {
//     margin: 0 -12px 24px -12px !important;
//     width: calc(100% + 24px) !important;

//     & > * {
//       margin: 0 12px 12px 12px !important;
//     }
//   }
// }

.product-icons {
  display: grid;
  margin: 0 -24px 24px -24px !important;
  width: calc(100% + 48px) !important;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;

  .faq {
    grid-column: 6;
  }

  @media screen and (max-width: 1070px) {
    margin: 0 -12px 24px -12px !important;
    width: calc(100% + 24px) !important;
    grid-template-columns: repeat(4, 1fr);

    .faq {
      grid-column: 4;
    }
  }

  // @media screen and (max-width: 1070px) {
  //   margin: 0 -12px 24px -12px !important;
  //   width: calc(100% + 24px) !important;
  //   grid-template-columns: repeat(4, 1fr);

  //   .faq {
  //     grid-column: 4;
  //   }
  // }
}
