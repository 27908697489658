.current-reservation-shift-card-container {
  height: 100%;
  .inner-scrollable-container {
    padding: 0px !important;
    width: 100%;
  }

  .topOverflowGradient {
    display: none;
  }

  .bottomOverflowGradient {
    display: none;
  }

  .details-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .details-row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 10px 16px;
      border-bottom: 1px solid var(--color-border);
      width: 100%;
      :nth-child(2) {
        text-align: right;
      }
    }

    .actions-container {
      display: flex;
      flex-direction: column;

      & > :not(:last-child) {
        margin-bottom: 8px !important;
      }
    }
  }
  .notes-tab-container {
    padding: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .comment-buttons-container {
      display: flex;
    }
  }
}
