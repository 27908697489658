.table-container {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  table {
    overflow-x: scroll;
    th {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-bottom: 1px solid var(--color-border) !important;
      height: 55px !important;
    }
    tr {
      border-bottom: 1px solid var(--color-border) !important;
    }
  }
  td {
    border: none !important;
  }
  background: var(--color-paper);

  &.borders {
    border: 1px solid var(--color-border);
    border-radius: 6px;
  }

  .context-actions-header {
    text-align: center;
    position: sticky;
    right: 0px;
    top: 1px;
    border-bottom: 1px solid var(--color-border) !important;
    // background: var(--color-paper);
    // border-bottom: 2px solid var(--color-border) !important;
    // border-color: var(--color-border) !important;
  }
  .context-action-cell {
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: space-around;
    vertical-align: center;
    text-align: center;
    position: sticky;
    right: 0px;
    // background: var(--color-paper);
    border-color: var(--color-border) !important;
  }

  .MuiTableCell-root {
    padding: 3px 16px;
    min-height: 54px;
    height: 100%;
    align-items: center;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: var(--color-paper);
    padding: 4px 16px;
    padding-right: 60px;
  }

  .rpp-text {
    margin-right: 10px;
  }

  .select-page-size {
    width: 50px;
  }

  .action-checkbox {
    width: 30px;
  }
}

.addable-cell-container {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none !important;
    // -webkit-text-fill-color: var(--color-text-subdued);
    -webkit-box-shadow: 0 0 0px 1000px var(--color-paper) inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }
}

.editable-table-cell-input {
  color: var(--color-text-subdued);
  border: none;
  outline: none;
  background: var(--color-paper) !important;
  // -webkit-text-fill-color: var(--color-text-subdued);
  ::-ms-fill {
    // color: var(--color-text-subdued);
    background: var(--color-paper) !important;
  }
  :-webkit-autofill {
    // color: var(--color-text-subdued);
    background: var(--color-paper) !important;
  }
}

.editable-table-cell-select {
  // color: var(--color-text-subdued);
  border: none;
  outline: none;
  background: var(--color-paper);

  span {
    // color: var(--color-text-subdued);
  }

  input:focus {
    background: var(--color-paper) !important;
  }
}

.dragHandle {
  position: absolute;
  top: calc(50% - 10px);
  left: -10px;
  height: 20px;
  width: 20px;
  opacity: 0;
}

.MuiTableRow-root {
  position: relative;
  &:hover {
    .dragHandle {
      position: absolute;
      top: calc(50% - 10px);
      left: -10px;
      height: 20px;
      width: 20px;
      opacity: 1;
    }
  }

  &.dragged {
    background-color: var(--color-bg);
    .table-cell-container {
      display: flex !important;
      align-items: center !important;
    }
    .context-action-cell {
      min-width: 150px;
      border-color: var(--color-border) !important;
    }
  }

  .table-cell-container {
    input {
      color: var(--color-text) !important;
      width: 100% !important;
    }
    select {
      color: var(--color-text);
    }
    span {
      color: var(--color-text);
    }
  }
}
