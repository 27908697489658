.mobile-homepage-body {
  display: flex;
  flex-direction: column;

  .cards {
    padding: 10px;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
}

#rail {
  display: flex;
  & > :not(:last-child) {
    margin-right: 10px;
  }
}
