.featureNavigation {
  justify-content: center;
  height: 99vh;
  width: 32px;
  position: relative;

  & > div {
    margin-bottom: 24px;
  }

  & > div:last-child {
    margin-bottom: 0px;
  }

  .path {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;

    color: var(--color-text-subdued);

    svg {
      height: 30px;
      width: 30px;
      fill: var(--color-text-subdued);
    }

    &.active {
      background: var(--color-secondary);
      color: var(--color-onSecondary, #ffffff);

      svg {
        fill: var(--color-onSecondary, #ffffff);
      }
    }
  }

  .bottomNavigation {
    position: absolute;
    bottom: 16px;
    left: 0;
  }
}
