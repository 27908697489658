.image-field {
  .image-field-box {
    background-color: var(--color-border);
    height: 160px;
    width: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .image-field-img {
    max-width: 300px;
    max-height: 160px;
  }
}

.image-multiple-container {
  .multiple-image-small {
    color: var(--color-text-subdued);
    background-color: var(--color-border);
    margin-right: 8px;
    background-size: cover;
    background-position: center;
    height: 48px;
    width: 64px;
    border-radius: 2px;
  }

  .multiple-image-medium {
    color: var(--color-text-subdued);
    background-color: var(--color-border);
    margin-right: 8px;
    background-size: cover;
    background-position: center;
    height: 70px;
    width: 120px;
    border-radius: 4px;
  }

  .image-row {
    margin-bottom: 8px;
    cursor: default;
    width: 100%;
    user-select: none;
    position: relative;

    .drag-icon {
      color: var(--color-text-subdued);
      margin-right: 8px;
      width: 24px;
      .drag-indicator {
        display: none;
      }
    }

    .delete-btn {
      display: none;
    }

    &:hover {
      .drag-icon {
        .drag-indicator {
          display: block;
        }
      }
      .delete-btn {
        display: block;
      }
    }
  }
}

.image-field-btn-container {
  .image-field-add-btn {
    margin-right: 16px !important;
    height: 40px;
  }
}
