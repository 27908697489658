.timeTable-screen {
  --sidebar-width: 280px;

  @media screen and (max-width: 900px) {
    --sidebar-width: 230px;
  }

  .featureNavigation {
    top: 0px;
    position: fixed;
    left: 8px;
    bottom: 0px;
  }

  .timeTable-container {
    position: absolute;
    top: 48px;
    left: 48px;
    right: 0px;
    bottom: 0px;
    padding-left: 0px !important;
    display: grid;
    width: calc(100vw - 48px);
    height: calc(100vh - 50px);
    grid-template-columns: var(--sidebar-width) 1fr;
    grid-template-rows: 100%;

    .timeTable-Inner {
      width: 100%;
      max-width: calc(100vw - 328px);
    }

    &.sidebar-open {
      grid-template-columns: var(--sidebar-width) 1fr var(--sidebar-width);

      .timeTable-Inner {
        max-width: calc(100vw - 608px);
      }
    }

    @media screen and (max-width: 900px) {
      grid-template-columns: var(--sidebar-width) 1fr;
      grid-template-rows: 100%;

      .timeTable-Inner {
        width: 100%;
        max-width: calc(100vw - 278px);
      }

      &.sidebar-open {
        grid-template-columns: var(--sidebar-width) 1fr var(--sidebar-width);

        .timeTable-Inner {
          max-width: calc(100vw - 508px);
        }
      }
    }
  }

  #timeTableCard {
    --color-scrollbar-thumb: var(--color-text);
  }
}
