.guestCard {
  border-radius: 0px !important;
  border-bottom: 1px solid var(--color-border);
  &.active {
    background-color: rgba(16, 203, 184, 0.07);
    border: 1px solid var(--color-primary);
  }

  &:hover {
    background-color: var(--color-grey-1);

    .darkMode & {
      background-color: var(--color-grey-8);
    }
  }
}
