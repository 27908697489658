.MuiIconButton-root {
  &.icon-btn-x-small {
    padding: 2px;
    color: var(--color-text);
    & svg {
      height: 1.1rem;
      width: 1.1rem;
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: all;
      color: var(--color-disabled);
    }
  }

  &.icon-btn-small {
    padding: 2px;
    color: var(--color-text);
    & svg {
      height: 1.5rem;
      width: 1.5rem;
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: all;
      color: var(--color-disabled);
    }
  }

  &.icon-btn-medium {
    padding: 4px;
    color: var(--color-text);
    & svg {
      height: 24px;
      width: 24px;
    }

    &:disabled {
      color: var(--color-disabled);
    }
  }

  &.icon-btn-large {
    padding: 2px;
    color: var(--color-text);
    & svg {
      height: 2rem;
      width: 2rem;
    }

    &:disabled {
      color: var(--color-disabled);
    }
  }

  &.color-textPrimary {
    color: var(--color-text);
  }

  &.color-primary {
    color: var(--color-primary);
  }

  &.color-secondary {
    color: var(--color-secondary);
  }

  &.color-error {
    color: var(--color-critical);
  }

  &.color-subdued {
    color: var(--color-text-subdued);
  }

  &.color-disabled {
    color: var(--color-disabled);
  }

  &.color-tertiary {
    color: var(--color-tertiary);
  }
}
