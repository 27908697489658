.reservation-rules-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: var(--color-paper);
  .title-container {
    margin-bottom: 20px;
    :nth-child(1) {
      margin-bottom: 6px;
    }
  }
  .table-container {
  }
  .reservation-table {
    width: 100%;
    margin-bottom: 24px;
    border-collapse: collapse;
    tr {
      border-bottom: 1px solid var(--color-border) !important;
      height: 48px !important;
    }
    .rulesets-cell {
      width: 60%;
      text-align: left;
    }

    .actions-cell {
      width: 15% !important;
    }
    .plus-minus-cell {
      width: 3%;
    }
    .length-cell {
      width: 25% !important;
      text-align: center !important;
      font-family: Circular Std;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
    }
  }
}
.modal-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    width: 60%;
  }
  .right {
    min-height: 146px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
