.fullscreen-modal {
  .MuiPaper-root {
    background: var(--color-paper);
  }

  &.MuiDialog-root {
    z-index: 10000 !important;
  }

  .fullscreen-modal-app-bar {
    border-bottom: 1px solid var(--color-border);
    background: var(--color-paper);
    box-shadow: none;
  }

  .fullscreen-modal-title {
    margin-left: 12px;
    flex: 1;
  }
}
