.pin-alert-modal-container {
  z-index: 1000000; //should be biggest on app
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: var(--color-bg);

  -ms-overflow-style: none;
  scrollbar-width: none;
  --scrollbar-size: 0px;
  --scrollbar-height: 0;

  .pin-alert-modal-footer {
  }

  .pin-alert-modal-container-small {
    z-index: 1000000; //should be biggest on app
  }

  .pin-alert-modal-backdrop {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .pin-alert-modal-footer-small {
  }

  .pin-alert-wrapper {
    font-family: sans-serif;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 100vh;
  }

  .p-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 20px;

    width: 72px;
    height: 72px;

    background: #10ccb9;
    border-radius: 48px;

    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;
    /* or 43px */

    text-align: center;

    color: #011311;

    cursor: pointer;
  }

  .p-button.large {
    width: 96px;
    height: 96px;
  }
  .p-button.small {
    width: 58px;
    height: 58px;
  }

  .p-button svg {
    height: 32px;
    width: 32px;
  }

  .p-button:active {
    opacity: 70%;
  }

  .row {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }

  .pinBit {
    display: 'block';
    height: 24px;
    width: 24px;
    border-radius: 12px;
  }

  .code {
    position: relative;
  }

  .message {
    display: block;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 120%;
    top: -50px;
    position: absolute;
    text-align: center;
  }

  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-10px);
    }
    50% {
      transform: translateX(10px);
    }
    75% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0);
    }
  }

  /* Apply the animation to the element */
  .shake {
    animation-name: shake;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .timeout {
    position: fixed;
    bottom: 50px;
    right: 50px;
    flex-direction: column;
    gap: 0px;
    line-height: 90%;
    background-color: var(--color-secondary);
    font-size: 28px;
    color: #fff;
    padding-bottom: 5px;
  }

  .timeout::after,
  .timeout::before {
    content: 'Minuten';
    display: block;
    font-size: 12px;
    line-height: 110%;
  }

  .timeout::before {
    content: 'für';
  }
}
