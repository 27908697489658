.tabs {
  --scrollbar-size: 0.1px;
  --scrollbar-height: 20;
}

.tab {
  position: relative;

  .badge {
    position: absolute;
    display: flex;
    background-color: var(--color-secondary);
    color: var(--color-onSecondary);
    justify-content: center;
    align-items: flex-start;
    width: 16px;
    height: 16px;
    right: -2px;
    top: 14px;
    border-radius: 8px;
    font-size: 0.675rem;
    line-height: 140%;
    letter-spacing: 0px;
    font-weight: 500;
  }

  .x-icon {
    margin-left: 8px;
    z-index: 100;
  }

  &.variant-1 {
    width: auto;
    padding: 16px;
  }

  &.variant-2 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    &.active {
      background-color: rgba(16, 204, 185, 0.2);
    }
  }
}

.active-slider {
  width: 80%;
  position: absolute;
  height: 2px;
  bottom: 0px;
  background-color: var(--color-primary);
  transition: left ease 200ms, width ease 100ms;
}

.tab-info-number {
  position: absolute;
  top: 7px;
  right: 2px;
  background-color: var(--color-warning);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  border-radius: 50%;
}
