.additional_settings {
  padding: 10px;
  border-radius: 4px;
  background-color: var(--color-btn-default);

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.open {
      margin-bottom: 12px;
    }

    h6 {
      font-size: 16px;
      line-height: 19.2px;
    }

    svg {
      width: 20px;
      height: 20px;
      color: var(--color-text);
    }
  }
}
