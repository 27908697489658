.shifts-list-view-wrapper {
  padding: 14px;
  overflow: scroll;
  // height: calc(100vh - 70px);
  background: var(--color-paper);

  --scrollbar-size: 6px;
  .shifts-list-view-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--color-paper);
  }

  .day-row-container {
    display: flex;
    padding: 0 0 4px 0;
    border-bottom: 1px solid var(--color-border);
  }

  .day-row-date {
    display: flex;
    align-items: flex-start;
    width: 200px;
    padding: 12px 0 0 12px;
  }

  .day-row-shifts {
    display: flex;
    flex-direction: column;
    padding-top: 4px;
    & > :not(:last-child) {
      margin-right: 12px;
    }
  }

  .day-row-shift {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px;
    cursor: pointer;
    & > :not(:last-child) {
      margin-right: 12px;
    }

    &:hover {
      background: var(--color-border);
    }
  }

  .shift-list-item-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
}
