.radio-button-group {
  .MuiIconButton-root {
    padding: 0px 9px;
    padding-right: 8px;
  }

  .MuiTypography-body1 {
    font-size: 1rem;
    line-height: 140%;
    letter-spacing: 0px;
    font-weight: normal;
    color: var(--color-text-subdued);

    &.Mui-disabled {
      color: var(--color-disabled);
    }
  }

  label {
    margin-bottom: 16px;
  }
}
