.left-sidebar {
  border-radius: 0 !important;
  --scrollbar-size: 8px;

  -webkit-overflow-scrolling: touch;
}
.numberIcon {
  color: var(--color-text-subdued);
  margin-right: 2px;
}
