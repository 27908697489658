.bottom-nav-bar {
  &.desktop-version {
    display: none;
  }

  position: fixed;
  bottom: 0;
  max-height: 72px;
  height: 70px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-paper);
  border-top: 1px solid var(--color-border);

  &.icons {
    svg {
      height: 24px;
      width: 24px;

      path {
        fill: var(--color-text-subdued);
      }

      &.selected {
        path {
          fill: var(--color-secondary-1);
        }
      }
    }
  }
}
