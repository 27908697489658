.total-reservation-day {
  min-width: 10%;
  @media screen and (min-width: 600px) {
    margin: 0 6px;
  }

  &.mobile-design {
    width: 90px;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
