.changelog-modal-wrapper {
  max-height: 60vh;
  overflow: scroll;
}

.changelog-container {
  margin-top: 12px;
  // max-height: 50vh;
  overflow: hidden;
  .update-container {
    padding: 10px;
    border: 1px solid var(--color-border);
    border-radius: 2px;
    background: var(--color-bg);
    margin-bottom: 12px;
  }

  .update-title-container {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    & > :not(:last-child) {
      margin-right: 8px;
    }
  }

  .go-to-feature-button {
    margin-top: 16px;
  }

  .update-media-container {
    height: auto;
  }

  .update-media {
    width: 95%;
    height: auto;
  }

  .update-media-img {
    max-width: 100%;
  }
}
