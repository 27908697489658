#floorPlan {
  width: 100%;
  height: 100%;
  //min-height: calc(100vh - 60px);
  position: relative;
  padding: 20px;
  overflow: hidden;

  .spaceSwitchContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: calc(100% - 50px);
  }
}

.spaceSwitchMenu .MuiPaper-rounded {
  bottom: 20px;
  top: auto !important;
  overflow: auto;
}

.spaceSwitch {
  min-width: 200px;
  .MuiSelect-root {
    background: var(--color-paper);
    margin-top: auto;
  }
}

#swippable {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
