.slot-settings-container {
  display: flex;
  flex-direction: column;
  background: var(--color-paper);
  width: 100%;
  padding: 20px;

  .input-fields-container {
    display: flex;
    margin-top: 24px;
    & > :not(:last-child) {
      margin-right: 24px;
    }
  }

  .input-field-container {
    width: 200px;
    display: flex;
    flex-direction: column;
  }

  .slot-table {
    position: relative;
    border: none;
    tr {
      height: 42px !important;
      align-items: center;
      vertical-align: middle;
    }
    td {
      vertical-align: middle;
      text-align: center;
      align-content: center;
    }
  }

  .blocked-container {
    vertical-align: middle;
    align-content: center;
  }

  .blank-slot {
    width: 70%;
  }

  .blank {
    width: 5%;
  }

  .time-slot {
    width: 100%;
    height: 42px !important;
    display: flex;
    align-items: center;
    text-align: left;
    vertical-align: middle;
    position: relative;
    z-index: 0;
    padding-left: 12px !important;
  }

  .slot-background {
    background: rgba(16, 204, 185, 0.8);
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .time-hour {
    //font
    font-family: Circular Std;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 130%;
    color: var(--color-primary-5);
  }
  .gradient-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    //opacity: 0.25;
    background: linear-gradient(
      180deg,
      var(--color-paper) 0%,
      var(--color-transparent) 106.78%
    );
    transform: rotate(180deg);
    z-index: 500;
  }
}
