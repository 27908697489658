.notificationCard {
  cursor: pointer;

  .cardIconImage {
    border-radius: 6px !important;
    width: 40px !important;
    height: 40px !important;
    background: var(--color-secondary) !important;
  }
  .alignCardIcon {
    margin-right: 16px;
    align-self: flex-start;
  }

  .closeNotification {
    position: absolute;
    top: 8px;
    right: 8px;
    opacity: 0;
    user-select: none;
    transition: opacity 200ms ease;
    background: var(--color-paper);
  }

  &.new {
    background: var(--color-grey-2);

    .darkMode & {
      background: var(--color-grey-7);
    }
  }

  &:hover {
    .closeNotification {
      opacity: 1;
    }

    .closeNotification {
      background: var(--color-grey-1);
    }

    background: var(--color-grey-1);

    .darkMode & {
      background: var(--color-grey-8);

      .closeNotification {
        background: var(--color-grey-8);
      }
    }
  }
}
