.tablePlan-screen {
  overflow: hidden;
  position: fixed;
  height: 99vh;
  width: 100vw;
  top: 0;
  left: 0;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  &.hideLiveResas {
    outline: 4px solid var(--color-critical);
    outline-offset: -4px;

    &::before {
      content: '';
      position: fixed;
      top: 48px;
      height: 4px;
      width: 100vw;
      background-color: var(--color-critical);
      z-index: 9999;
    }

    .hideLiveResasMsg {
      position: fixed;
      top: 48px;
      left: 0px;
      background: var(--color-critical);
      color: #fff;
      padding: 2px 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 10000;
    }
  }

  .customSidebar {
    .text-4 {
      font-size: 0.9em;
    }
    .text-3 {
      font-size: 1em;
    }

    .text-2 {
      font-size: 1.1em;
    }

    .MuiSvgIcon-root {
      font-size: 1.6em;
    }

    .MuiIconButton-root {
      font-size: 1.6em;
    }

    .MuiIconButton-root.icon-btn-small svg {
      font-size: 1.6em;
    }

    .tab span {
      font-size: 0.9em;
    }

    .res-additional-button,
    .res-additional-button span {
      font-size: 0.9em;
    }

    .MuiIconButton-root.icon-btn-small svg {
      height: 0.6em;
      width: 0.6em;
    }

    h5,
    .h5 {
      font-size: 1.1em;
    }

    .MuiInputBase-root {
      font-size: 1em;
    }

    .MuiButton-root {
      font-size: 0.9em;
    }

    .btn,
    .button {
      font-size: 1.1em;
    }
  }

  --sidebar-width: 280px;

  @media screen and (max-width: 880px) {
    --sidebar-width: 230px;
  }

  .featureNavigation {
    top: 0px;
    position: fixed;
    left: 8px;
    bottom: 0px;
  }

  .tableplan-container {
    position: absolute;
    top: 48px;
    left: 48px;
    right: 0px;
    bottom: 0px;
    display: grid;
    width: calc(100vw - 48px);
    height: calc(99vh - 68px);
    grid-template-columns: var(--sidebar-width) 1fr;
    grid-template-rows: 100%;

    &.sidebar-open {
      grid-template-columns: var(--sidebar-width) 1fr var(--sidebar-width);
    }

    &.edit-reservation {
      grid-template-columns: 1fr var(--sidebar-width);
    }
  }
}

.tableplanscreen.hideOverflow {
  height: 99vh;
  overflow: hidden;
  .intercom-lightweight-app {
    display: none;
  }
}

@media (hover: none) and (pointer: coarse) and (min-height: 600px) {
  .hideOverflow {
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
    position: initial !important;
    // position: fixed;
    height: 99vh;
    width: 100vw;
    top: 0;
    left: 0;
  }
}
