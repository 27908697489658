#event-settings-container {
  .max-column-width {
    max-width: 45%;
  }

  .max-600-width {
    max-width: 600px;
  }

  .event-title-and-description {
    width: 100%;
    // border: 1px solid var(--color-border);
    // border-radius: 6;
  }

  .event-type-btns-container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  .event-switch-width {
    width: auto;

    .MuiFormControlLabel-root {
      margin-right: 0;
    }
  }

  .block-table {
    background-color: var(--color-bg);
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid var(--color-text-subdued);

    .conf-email-type-unit {
      span {
        margin: 6px;
      }
    }
  }
}

.event-email-settings-container {
  margin-bottom: 22px;

  .confirmation-email-container {
    margin-right: 22px;
  }

  // .reminder-email-container {
  // }
}
