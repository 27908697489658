.new-signature-modal-container {
  padding: 16px;
  .new-signature-actions-container {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > :not(:last-child) {
      margin-right: 12px;
    }
  }
}
