.mobile-product-icons {
  display: flex;
  grid-gap: 8px;
  gap: 18px;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    width: 88px;
  }
}
