.total-res-today {
  .right {
    justify-content: flex-end;
    @media screen and (max-width: 450px) {
      width: 30%;
    }

    & > * {
      margin-right: 8px;
    }

    & > *:last-child {
      margin-right: 0px;
    }
  }
}
