.waitinglist-card {
  position: relative;
  overflow: hidden;
  border-radius: 0 !important;
  padding: 4px 16px;
  background: var(--color-bg);
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 8px;
    height: 100%;
    background-color: #6369d1;
  }

  &.waitinglist-pending {
    &::before {
      background-color: #6369d1;
    }
  }

  &.waitinglist-success {
    &::before {
      background-color: #b5d16d;
    }
  }

  &.waitinglist-waiting {
    &::before {
      background-color: #ee4266;
    }
  }

  &.waitinglist-canceled,
  &.waitinglist-failed {
    &::before {
      background-color: #cc1023;
    }
  }

  &.active {
    background: var(--color-border);
  }

  .text-subdued {
    color: var(--color-text-subdued);
  }

  .text-white {
    color: var(--color-onSecondary);
  }

  .text-3 {
    color: var(--color-text);
  }
}
