.mailbox-container {
  position: fixed;
  top: 57px;
  right: 40px;
  z-index: 10;
  width: 400px;
  transform: translate(40px, -22px) scale(0.9);
  max-height: 90vh;
  overflow: scroll;
  --scrollbar-size: 4px;
  --scrollbar-height: 0;

  & .box {
    background-color: var(--color-paper);
    padding: 4px;
    border-radius: 8px;
  }

  & .box:nth-child(0) {
    margin-top: 40px;
  }

  & .close-button-container {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 0px;
    z-index: 100;

    .close-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #333333;
      border-radius: 30px;
      color: #ffffff;
      border: none;
      outline: none;
      padding: 8px 12px;
      cursor: pointer;
    }
  }
}

.sidebarOpen {
  .mailbox-container {
    right: 290px;
  }
}
