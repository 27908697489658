.dayheader-container {
  // min-height: 90px;
  position: relative;
  min-width: 96px;
  padding: 12px 0px 0px 0px;
  // border: 1px solid var(--color-border);
  border-radius: 1px;
  align-items: center;
  // background: #fff; Never use fixed colors they are mostly given by the component anyway

  .day-number {
    width: 32px;
    height: 32px;
    padding: 10px;
    border-radius: 50%;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    // color: #011311; Same as above on typography use the color prop
    // .darkMode & {
    //   color: var(--color-primary-5) !important;
    // }
  }

  .holiday {
    height: 17px;

    & > :not(:last-child) {
      margin-right: 4px;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 70px;
    }

    // .darkMode & {
    //   color: var(--color-primary-5) !important;
    // }
  }

  .circle {
    background: var(--color-warning);
    width: 12px;
    height: 12px;
    border-radius: 50%;

    &.error {
      background: var(--color-critical);
    }
  }

  .bottom-container {
    width: 100%;
    height: 12px;
    border: 1px solid var(--color-border);
    border-top: none;
    border-bottom: none;
  }

  .shiftContainer {
    position: absolute;
    top: calc(100% - 12px);
    width: 100%;
    left: 0px;
    padding: 2px;

    .shiftAllDay {
      overflow: hidden;
      border-radius: 4px;
      background: var(--color-primary);
      color: var(--color-primary-5);
      padding: 6px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      svg {
        display: inline;
        font-size: 1rem;
        margin-right: 4px;
      }

      span {
        line-height: 1fr;
      }
    }
  }
}
