.add-to-home-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;

  .tap-img {
    margin: 0 8px;
  }

  .last-row-container {
    margin: 34px 0 60px 0;
    align-items: center;
    justify-content: center;
  }
}