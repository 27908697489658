.mobile-navbar {
  z-index: 1299;
  position: sticky;
  top: 0px;

  .box {
    position: unset;
  }

  .icon {
    fill: var(--color-grey-7);
  }
}

.mobile-notifications-container {
}
