.toast {
  &.errorBodyWrapper {
    border: 1px solid var(--color-critical) !important;
    background: linear-gradient(
        0deg,
        rgba(204, 16, 35, 0.03),
        rgba(204, 16, 35, 0.03)
      ),
      var(--color-bg) !important;

    .logo {
      color: var(--color-critical);
      width: 20px !important;
      height: 20px !important;
    }
  }

  &.successBodyWrapper {
    border: 1px solid var(--color-success) !important;
    background: linear-gradient(
        0deg,
        rgba(73, 184, 0, 0.03),
        rgba(73, 184, 0, 0.03)
      ),
      var(--color-bg) !important;

    .logo {
      color: var(--color-success);
      width: 20px !important;
      height: 20px !important;
    }
  }

  &.warningBodyWrapper {
    border: 1px solid var(--color-warning) !important;
    background: linear-gradient(
        0deg,
        rgba(244, 176, 42, 0.03),
        rgba(244, 176, 42, 0.03)
      ),
      var(--color-bg) !important;

    .logo {
      color: var(--color-warning);
      width: 20px !important;
      height: 20px !important;
    }
  }

  &.infoBodyWrapper {
    border: 1px solid var(--color-secondary) !important;
    background: linear-gradient(
        0deg,
        rgba(99, 105, 209, 0.03),
        rgba(99, 105, 209, 0.03)
      ),
      var(--color-bg) !important;

    .logo {
      color: var(--color-secondary);
      width: 20px !important;
      height: 20px !important;
    }
  }

  .severityTitle {
    text-transform: capitalize;
  }

  .descriptionWrapper {
    padding-top: 8px;
  }

  .closeBtn {
    width: 14px !important;
    height: 14px !important;
    color: var(--color-text-subdued);
  }
  .alignErrorIcon {
    align-self: flex-start;
    margin-right: 8px;
  }
}
