.overlapping-shifts-row-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  :hover {
    cursor: pointer;
  }
  & > :not(:last-child) {
    margin-right: 8px;
  }
}
