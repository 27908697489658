.timeTable {
  --scrollbar-size: 8px;
  .overlap {
    z-index: 11;
    position: absolute;
    touch-action: none;
    pointer-events: none;
    background-position: center;
    background-size: auto;
    border-radius: 4px;
    cursor: not-allowed;
  }

  .blinker {
    z-index: 13;
    position: absolute;
    touch-action: none;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
  }

  .track {
    position: relative;
    width: fit-content;
  }

  .timeTable-inner {
    .header-Container {
      height: 30px;
      width: 100%;
      position: relative;
      z-index: 100;
      padding-left: 16px;
      h5 {
        position: sticky;
        left: 0px;
        width: fit-content;
      }
    }
  }

  .time__table {
    border-spacing: 0px;

    span {
      cursor: default;
    }

    tr {
      height: 24px;

      td {
        min-width: 60px;
        border-top: 1px solid var(--color-border);

        &:nth-child(1n) {
          border-left: 1px solid var(--color-border);
        }
      }

      th {
        min-width: 86px;
        position: sticky;
        left: 0px;
        z-index: 14;
        background: var(--color-paper);
        text-align: left;
        height: 24px;
        // display: flex;
        // justify-content: flex-start;
        // align-items: center;
      }

      &:not(.times):hover {
        td {
          background-color: var(--color-grey-1);

          .darkMode & {
            background-color: var(--color-grey-8);
          }
        }
      }

      &.times {
        span {
          margin-left: 2px;
          cursor: default;
        }
      }

      &.active {
        td {
          background-color: var(--color-grey-2);

          .darkMode & {
            background-color: var(--color-grey-7);
          }
        }
      }

      &.blockedTable {
        td {
          background-color: var(--color-grey-3);

          .darkMode & {
            background-color: var(--color-grey-6);
          }
        }

        &.active,
        &:hover {
          td {
            background-color: var(--color-grey-4);
            .darkMode & {
              background-color: var(--color-grey-5);
            }
          }
        }
      }
    }
  }

  .currentTime {
    position: absolute;
    width: 1px;
    background-color: var(--color-tertiary);
    z-index: 12;

    &::before {
      content: "";
      height: 8px;
      width: 8px;
      display: block;
      border-radius: 8px;
      background-color: var(--color-tertiary);
      transform: translate(-3.5px, 0px);
    }
  }
}
