.auth-container {
  @media screen and (max-width: 850px) {
    height: calc(100vh - 65px);
  }

  .or-divider {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 24px 0;

    .or-text {
      color: var(--color-grey-4);
      margin: 0 8px;
    }

    .dividers {
      width: 100%;
      border: solid 1px var(--color-grey-4);
      background-color: var(--color-grey-4);
    }
  }

  .email-btn {
    margin-bottom: 64px;

    span {
      margin-left: 16px;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: var(--color-grey-7);
    }
  }
}
