.group {
  width: 24px !important;
  height: 24px;
  border-radius: 3px;
  margin: 1px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: var(--color-primary);
}
.LeftArrow {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  z-index: 1000;
  background: linear-gradient(
    -90deg,
    rgba(1, 19, 17, 0) 0%,
    rgba(1, 19, 17, 0.5) 105%
  );

  .MuiIconButton-root {
    border-radius: 0%;
  }
}
.RightArrow {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  background: linear-gradient(
    90deg,
    rgba(1, 19, 17, 0) 0%,
    rgba(1, 19, 17, 0.5) 105%
  );

  .MuiIconButton-root {
    border-radius: 0%;
  }
}
