.largeGroupTable {
  // border: 1px solid lightgray;
  --color-scrollbar-thumb: var(--color-border);
  --scrollbar-size: 8px;
  --scrollbar-height: 1.25;
  width: 100%;
  font-size: 80%;
  overflow: scroll;

  margin-bottom: 10px;

  table {
    width: 100%;
    border-collapse: collapse;
    position: relative;
  }

  td,
  th {
    // border: 1px solid #ddd;
    padding: 8px;
    white-space: nowrap;
    vertical-align: top;
    background-color: var(--color-paper);
  }

  th:nth-child(1),
  td:nth-child(1),
  .sticky {
    position: sticky;
    left: 0;
    z-index: 10;
  }

  th:last-child,
  td:last-child {
    position: sticky;
    right: 0;
    z-index: 10;
    text-align: right;
  }

  tr:nth-child(even) {
    td,
    th {
      background-color: var(--color-bg);
    }
  }

  // tr:hover {
  //   // background-color: #ddd;
  // }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    // background-color: #04aa6d;
    // color: white;
  }

  tfoot {
    color: gray;
  }

  tfoot th {
    font-weight: normal;
    position: relative !important;
  }

  tfoot {
    th:nth-child(1) {
      position: relative !important;
    }
  }

  .pagination {
    display: flex;
    gap: 8px;
    align-items: center;

    z-index: 10000;
    position: sticky;
    left: 10px;

    // transform: translateY(-30px);

    button {
      width: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      line-height: 18px;
      padding-bottom: 2px;
      background-color: var(--color-bg);
      color: var(--color-text);

      &:disabled {
        opacity: 0.5;
      }
    }

    select {
      background-color: var(--color-bg);
      color: var(--color-text);
      border: none;
      outline: none;
      height: 20px;
      cursor: pointer;
    }
  }
}

#reservation-stat-screen {
  .featureNavigation {
    top: 0px;
    position: fixed;
    left: 8px;
    bottom: 0px;
  }
  .container.reservations {
    padding-left: 64px;
  }
}
