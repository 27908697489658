.scroll-area {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .scroll-view {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }

  .scroll-bar {
    display: flex;
    // ensures no selection
    user-select: none;
    // disable browser handling of all panning and zooming gestures on touch devices
    touch-action: none;
    padding: 2px;
    background: var(--color-4);
    transition: background 160ms ease-out;

    &:hover {
      background: var(--grey-5);
    }
    &[data-orientation="vertical"] {
      width: 8px;
    }
    &[data-orientation="horizontal"] {
      flex-direction: column;
      height: 8px;
    }
  }
}
