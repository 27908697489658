.shiftMessage {
  display: flex;
  padding: 10px;
  max-width: 80vw;
  max-height: 200px;
  padding-left: 14px;
  padding-right: 24px;
  border-radius: 40px;
  background: var(--color-paper);
  align-items: center;
  width: auto;
  margin-left: 16px;

  .icon {
    color: var(--color-secondary);
    margin-right: 8px;
    height: 32px;
    width: 32px;
  }

  a {
    color: var(--color-primary);
    margin-left: 4px;
  }

  &.error {
    background: var(--color-critical);
    color: #ffffff;

    .icon {
      color: #ffffff;
    }

    h5,
    span,
    a {
      color: #ffffff;
      width: auto;
      white-space: nowrap;
    }
  }

  .inner {
    width: auto;
  }
}
