.mobile-edit-reservation-modal-container {
  // padding-bottom: 200px !important;
  overflow: scroll !important;
  .mobile-modal-date-row-container {
    width: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.shift-cards-container {
  overflow-y: scroll;
  // height: 100vh;
  margin-top: 12px;
  & > :not(:last-child) {
    margin-bottom: 12px;
  }
}

.mobile-actions-btns-bottom-and-gradient {
  position: fixed;
  bottom: 0;
  padding: 30px 16px 24px 16px !important;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  z-index: 101;
  background: linear-gradient(
    180deg,
    var(--color-transparent),
    var(--color-white) 40%
  );
  height: 130px;

  .darkMode & {
    background: linear-gradient(
      180deg,
      rgba(26, 32, 44, 0),
      var(--color-grey-9) 40%
    );
  }

  &.column-direction {
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    // height: calc(100% - 24rem);
  }
}

.mobile-edit-reservation-modal-footer {
  & > :not(:last-child) {
    margin-right: 14px;
    width: 45%;
  }

  & > :last-child {
    width: 65%;
  }
}
