#guest-statistics {
  .loading-stats-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .featureNavigation {
    top: 0px;
    position: fixed;
    left: 20px;
    bottom: 0px;
    z-index: 1;
  }

  .guest-statistics-container {
    padding: 30px 100px;
  }

  .individual-statistics-csv-container {
    display: flex;
    flex-direction: column;
    margin-top: 28px;
    & > :first-child {
      margin-bottom: 6px;
    }
    & > :nth-child(2) {
      margin-bottom: 12px;
    }
  }

  .download-csv-button {
    width: 180px;
  }

  .guest-statistics-title-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    & > :not(:last-child) {
      margin-bottom: 6px;
    }
  }

  .guest-statistics-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .reservation-statistics-container {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    & > :first-child {
      margin-bottom: 12px;
    }
  }

  .short-stats-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .short-stat-container {
    width: 31%;
    border: 1px solid var(--color-border);
    border-radius: 3px;
    padding: 20px 38px;
    display: flex;
    align-items: center;
    & > :not(:last-child) {
      margin-right: 32px;
    }
  }

  .icon-container {
    background: #6369d1;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .short-stat-text-container {
    display: flex;
    flex-direction: column;
    & > :not(:last-child) {
      margin-bottom: 6px;
    }
  }

  .graphs-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
  }

  .reservation-flow-graph-container {
    width: 65.5%;
    height: 300px;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-border);
    border-radius: 3px;
  }

  .reservation-types-graph-container {
    width: 31%;
    height: 300px;
    display: flex;
    padding: 12px 13px;
    flex-direction: column;
    border: 1px solid var(--color-border);
    border-radius: 3px;
    align-items: center;
  }
}
