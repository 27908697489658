.timeTableResCard {
  background-color: var(--color-primary);
  position: absolute;
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.7);
  align-items: center;
  cursor: grab;
  padding: 1px 8px;
  overflow: hidden;
  z-index: 10;
  display: flex;
  white-space: nowrap;

  strong {
    color: #ffffff;
  }

  &:active {
    cursor: grabbing;
  }

  &.gz4 {
    background-color: var(--color-primary-1);
    border-color: var(--color-primary-1);
  }
  &.gz6 {
    background-color: var(--color-primary-2);
    border-color: var(--color-primary-2);
  }
  &.gz8 {
    background-color: var(--color-primary-3);
    border-color: var(--color-primary-3);
  }
  &.gz10 {
    background-color: var(--color-primary-4);
    border-color: var(--color-primary-4);
  }

  &.blocked {
    background-color: var(--color-text);
    border-color: var(--color-text);
  }

  &.selected {
    border-color: var(--color-text);
  }

  &.gzSeated {
    background-color: #b10b45;
    border-color: #b10b45;
  }

  &.gzUpcoming {
    background-color: var(--color-primary-2);
    border-color: var(--color-primary-2);
  }

  &.gzOverdue {
    background-color: #f4b02a;
    border-color: #f4b02a;
  }

  &.gzDone {
    background-color: var(--color-table-none);
    border-color: var(--color-table-none);
  }

  &.shuffled {
    cursor: pointer;
  }
}
