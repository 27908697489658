.translations-container {
  // max-width: 670px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  background: var(--color-paper);
  border-radius: 5px;

  &.borders {
    border: 1px solid var(--color-border);
    border-radius: 6px;
  }

  .box.outline.el-0 {
    border: none !important;
  }

  // & > :first-child {
  //   margin: 0px 0px 0px 12px;
  // }

  .box .flex .space-between .rounded {
    padding: 0px !important;
  }
  .textfield-container {
    margin-bottom: 20px;
  }

  .rich-textfield-container {
    padding: 10px 0px;
    display: flex;
    flex-direction: column !important;

    .fr-box {
      border: 1px solid var(--color-border);
      & > :nth-child(1) {
        border: none !important;
      }
      //& > :nth-child(1) {
      //  margin-left: -6px !important;
      //}
      & > :nth-child(3) {
        border: none !important;
      }
      & > :nth-child(4) {
        border: none !important;
        border-top: 1px solid var(--color-border) !important;
      }

      .fr-newline {
        display: none !important;
      }
      .fr-toolbar {
        border-bottom: 1px solid var(--color-border) !important;
      }
    }
  }
  .rich-textfield-container-label {
    margin-bottom: 8px;
  }
}

.plus-button-container {
  width: 44px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  background: var(--color-grey-2);
  position: relative;

  .darkMode & {
    background: var(--color-grey-8);
  }

  .langs-popup {
    position: absolute;
    top: 40px;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background: var(--color-paper);
    border-radius: 4px;
    border: 1px solid var(--color-border);
    & > :not(:last-child) {
      border-bottom: 1px solid var(--color-border);
    }

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 40px;
      padding: 16px;
    }

    .item:hover {
      cursor: pointer;
    }
  }
}
