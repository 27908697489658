.offerShiftSettings {
  .offerSettingsInnerBox {
    max-height: calc(100vh - 160px);
    overflow-y: scroll;
    .searchBar {
      align-items: center;
      padding-left: 8px;
      background-color: var(--color-btn-default);
      border-radius: 4px;
      margin: 16px 0px;
      .MuiFilledInput-underline:before {
        border: none !important;
      }

      .MuiAutocomplete-popupIndicator {
        display: none !important;
      }

      .text-field .MuiFilledInput-root {
        background-color: var(--color-btn-default) !important;
      }
    }

    .allOffers {
      margin-bottom: 30px;
      .offerRow {
        display: flex;
        justify-content: space-between;
        border: 1px solid var(--color-btn-default);
        padding-right: 17px;
        .rightPart {
          align-items: center;
          padding-top: 10px;
          .reductionType {
            width: 100% !important;
            &:nth-child(2) {
              width: 150px !important;
            }
            .MuiInputBase-root {
              color: var(--color-text-subdued) !important;
            }
            div {
              width: auto !important;
            }
          }
        }
        div {
          width: fit-content;
          .MuiFilledInput-underline:before {
            border-bottom: none !important;
          }
        }
      }
    }

    .advertBannerTitle {
      align-items: baseline;
      justify-content: space-between;
      > :nth-child(2) {
        width: fit-content !important;
      }
    }

    .disabledPart {
      opacity: 0.3;
    }
  }
}

.offer-shift-setting-bottom-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
