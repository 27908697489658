.notificationV03Card {
  position: relative;

  .hideBtn {
    display: none;
  }

  &:hover {
    .hideBtn {
      display: block;
      position: absolute;
      top: 0px;
      right: 0px;
      background-color: #edf2f7;
      * {
        color: #011311;
      }
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-radius: 20px;
    background: var(--color-grey-2, #edf2f7);

    .color-textPrimary {
      color: #011311;
    }

    .message {
      color: #2d3748;
    }

    .header {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;

      .title {
        flex: 1 0 0;
      }
    }

    .actions {
      display: flex;
      align-items: stretch;
      gap: 4px;

      .action-card {
        display: flex;
        padding: 10px 0px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        flex: 1 0 0;
        border-radius: 10px;
        cursor: pointer;

        &.clicked {
          background: var(--color-grey-4, #cbd5e0);
        }

        &:hover {
          background: var(--color-grey-3, #cbd5e0);
        }

        &:active {
          background: var(--color-grey-4, #cbd5e0);
        }

        &.success {
          background: rgba(158, 226, 210, 0.75);
        }

        &.error {
          background: #f6d6d9;
        }
      }
    }

    .actionText {
      display: flex;
      padding: 2px 10px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      border-radius: 10px;
      background: var(--white-color, #fff);
      /* Popover-Shadow */
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2),
        0px 2px 10px 0px rgba(0, 0, 0, 0.1);

      &.success {
        background: rgba(158, 226, 210, 0.75);
      }

      &.error {
        background: #f6d6d9;
      }
    }

    .card {
      display: flex;
      padding: 8px 10px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 10px;
      background: #fff;
      /* Popover-Shadow */
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2),
        0px 2px 10px 0px rgba(0, 0, 0, 0.1);

      &.date {
        justify-content: space-between;
        align-items: center;

        .left {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .top {
            color: var(--color-secondary, #6369d1);
            font-family: Circular Std;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
          }
        }

        .right {
          display: flex;
          width: 60px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }

      &.reservation,
      &.rating {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        .top {
          color: var(--color-secondary, #6369d1);
          font-family: Circular Std;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }

        &.failed {
          .top {
            color: #f56565;

            &::before {
              content: 'Stornierte ';
            }
          }
        }
      }

      &.addOn {
        gap: 10px;

        .image {
          width: 60px;
          height: 60px;
          border-radius: 10px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;
        }
      }
    }
  }

  &.elevated {
    .inner {
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2),
        0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    }
  }
}
