.date-range-container {
  margin-bottom: 16px;
  .date-range-input {
    max-width: 256px;
    .edit-btn {
      height: 18px;
      width: 18px;
      color: var(--color-text-subdued);
    }
  }
}
