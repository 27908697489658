// .total-res {
//   @media screen and (min-width: 600px) {
//     margin: 0 -10px;
//   }
// }

.mobile-total-res-card {
  min-width: 327px;
  min-height: 120px;
  margin-bottom: 16px;
  border-radius: 6px !important;

  .divider {
    height: 0px !important;
    min-height: 0px !important;
  }

  .bottom-part {
    padding: 0 !important;
  }
}

// .mobile-edit-reservation-modal-container {
//   .mobile-modal-date-row-container {
//     width: 100%;
//     padding: 0px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//   }
// }

// .shift-cards-container {
//   overflow-y: scroll;
//   height: 100vh;
//   margin-top: 12px;
//   & > :not(:last-child) {
//     margin-bottom: 12px;
//   }
// }

// .mobile-edit-reservation-modal-footer {
//   position: absolute;
//   bottom: 0px;
//   height: 100px;
//   padding-top: 30px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   & > :not(:last-child) {
//     margin-right: 14px;
//     width: 25%;
//   }

//   & > :last-child {
//     width: 40%;
//   }
// }
