.mobile-select-restaurant {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;

  div {
    width: auto;
  }
}
