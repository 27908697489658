.select-restaurant {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;

  // max-height: 500px;

  div {
    width: auto;
  }
}
